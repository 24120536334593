import BtnCompo from "components/btn-compo";
import React, { useEffect, useState } from "react";
import Footer from "components/footer";
import { Button, Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const SignConfirm = () => {
  const [validated, setValidated] = useState(false);
  const [confirmsignYes, setConfirmsignYes] = useState("");
  const [profile, setProfile] = useState();
  const [address, setAddress] = useState();
  const [addInfo, setAddInfo] = useState();
  const [checkInfoData, setCheckInfoData] = useState();
  const [totalCost, setTotalCost] = useState();
  const [bankingDetailsDO, setBankingDetailsDO] = useState();
  const [mainId, setMainId] = useState();
  const [selfCoverData, setSelfCoverData] = useState();
  const [extendedFamily, setExtendedFamily] = useState([]);
  const [beneficiary, setBeneficiary] = useState();
  const [employeeDetails, setEmployeeDetails] = useState();
  const [representativeData, setRepresentativeData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [viewPDF, setViewPDF] = useState(false);
  const [code, setCode] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("representativeData")) {
      let representativeData = JSON.parse(localStorage.getItem("representativeData"));
      setCode(representativeData.representativeCode.toLowerCase());
      setRepresentativeData(representativeData);
    }

    if (localStorage.getItem("profile")) {
      let profile = JSON.parse(localStorage.getItem("profile"));
      setProfile(profile);
    }

    if (localStorage.getItem("profileAddress")) {
      let address = JSON.parse(localStorage.getItem("profileAddress"));
      setAddress(address);
    }

    if (localStorage.getItem("addInfo")) {
      let addInfo = JSON.parse(localStorage.getItem("addInfo"));
      setAddInfo(addInfo);
    }

    if (localStorage.getItem("checkInfoData")) {
      let checkInfoData = JSON.parse(localStorage.getItem("checkInfoData"));
      setCheckInfoData(checkInfoData);
    }

    if (localStorage.getItem("totalCost")) {
      let totalCost = JSON.parse(localStorage.getItem("totalCost"));
      setTotalCost(totalCost);
    }

    if (localStorage.getItem("bankingDetailsDO")) {
      let bankingDetailsDO = JSON.parse(
        localStorage.getItem("bankingDetailsDO")
      );
      setBankingDetailsDO(bankingDetailsDO);
    }

    if (localStorage.getItem("MainId")) {
      let mainId = JSON.parse(localStorage.getItem("MainId"));
      setMainId(mainId);
    }

    if (localStorage.getItem("selfCoverData")) {
      let selfCoverData = JSON.parse(localStorage.getItem("selfCoverData"));
      setSelfCoverData(selfCoverData);
    }

    if (localStorage.getItem("extendedFamily")) {
      let extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
      setExtendedFamily(extendedFamily);
    }

    if (localStorage.getItem("beneficiary")) {
      const beneficiary = JSON.parse(localStorage.getItem("beneficiary"));
      setBeneficiary(beneficiary);
    }

    if (localStorage.getItem("employeeDetails")) {
      let employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
      setEmployeeDetails(employeeDetail);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (confirmsignYes === "Yes" && viewPDF) navigate("/signature");
    else if (confirmsignYes === "No" && viewPDF) {
      let deviceInfo;
      if (localStorage.getItem("deviceInfo")) deviceInfo = JSON.parse(localStorage.getItem("deviceInfo"));
      setIsLoading(true);
      let payload = {
        sourceData: "funeralApp",
        csFuneralApp: "Yes",
        confirmsignYes: confirmsignYes,
        addInfo,
        extendedFamily,
        representativeData,
        bankingDetailsDO,
        deviceInfo,
        selfCoverData,
        ...profile,
        ...address,
        ...checkInfoData,
        ...totalCost,
        ...mainId,
        ...selfCoverData,
        ...beneficiary,
        ...employeeDetails,
        SourceSite: "csfuneral.co.za"
      };

      var config = {
        method: "post",
        url: "https://apiv2.msgl.ink/",
        headers: {
          "content-type": "application/json",
        },
        data: {
          ...payload,
        },
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setIsLoading(false);
          navigate("/thankyou");
        })
        .catch(function (error) {
          setIsLoading(false);
          window.location.reload(true);
        });
    }
    setValidated(true);
  };

  return (
    <Container>
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Application, please wait...</div>
      </div>}
      <div className="main-content">
        <h1 className="page-title mb-5">Signature Option</h1>

        <Form noValidate onSubmit={handleSubmit} validated={validated}>
          <Form.Group className="mb-2" controlId="sourceIncome">
            <Form.Label className="Label mb-2">
              * Please select one the following signing options
            </Form.Label>
            <Form.Check
              required
              type="radio"
              id="sign"
              label="Sign now"
              name="group1"
              value={confirmsignYes}
              onChange={(e) => setConfirmsignYes("Yes")}
            />
            <Form.Check
              type="radio"
              label={
                "Send a signing link to the following mobile number - " +
                profile?.cellNumber
              }
              id="link"
              name="group1"
              value={confirmsignYes}
              onChange={(e) => setConfirmsignYes("No")}
            />
            <Form.Control.Feedback type="invalid">
              Please choose a Signing option.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <div className="d-flex gap-3 w-100">
              <Form.Check
                required
                type="checkbox"
                id="check1"
                label="View Pdf and Terms and Conditions"
                onChange={(e) => { if (e.target.checked) setViewPDF(true); else setViewPDF(false) }}
              />
            </div>
          </Form.Group>


          {viewPDF && <div className="terms-container">
            <h6>1. Legislation information and requirements:</h6>
            <p>
              This policy is governed by South African Law and any legal action in relation to this policy is subject to the jurisdiction of the
              South African Courts.
            </p>
            <p className="blue-label">1.1 Personal Information</p>
            <p>In South Africa, the Protection of Personal Information Act 4 of 2013 (PoPIA) has been enacted to regulate the processing of
              Personal Information. When entering into this policy you provide us with information that may be protected by legislation includ
              ing but not limited to PoPIA. We will take all reasonable steps to protect the information you provide us securely and confident
              ially. Please read more how we use and protect your personal information in our Privacy Notice and PAIA manual available on
              our website https://www.shieldlife.co.za.</p>
            <p className="blue-label">1.2 Sharing of Insurance Information</p>
            <p>The South African Regulatory bodies require insurers to share information with the regulatory body regarding policies and claim
              In addition, insurers share information with each other regarding policies and claims with the view to prevent fraudulent claims
              and obtain material information regarding the assessment of risks proposed for insurance. By reducing the incidents of fraud
              and assessing risks fairly, future premium increases may be limited. This is done in the public interest and in the interest of all
              current and potential policyholders.
              By the insurer accepting or renewing this insurance, you or any other person that is represented herein, gives consent to the
              said information being disclosed to any other insurance company or its agent.You also similarly give consent to the sharing of
              information in regards to past insurance policies and claims that you have made.You also acknowledge that information provid
              ed by yourself or your representative may be verified against any legally recognised sources or databases.
              By insuring or renewing your insurance you hereby not only consent to such information sharing, but also waive any rights of
              confidentiality with regards to underwriting or claims information that you have provided or that has been provided by another
              person on your behalf. In the event of a claim, the information you have supplied with your application, together with the inform
              ation you supply in relation to the claim, will be made available to other insurers participating in the sharing of insurance inform
              ation. As a member of Shield Life's funeral plan, you, your dependants and extended family's personal information may be sha
              red with the employer. This will be limited to information that is relevant to you and/or your dependants’ and/or your extended's
              application or information that is required for the ongoing servicing of your funeral plan.</p>
            <p className="blue-label">1.3 Client Due Diligence</p>
            <p>As part of our responsibilities as an Accountable Institution in terms of the Financial Intelligence Centre Amendment Act 1 of
              2017, we are required to gather the following member information prior to concluding the transaction.
              The nature and purpose of this transaction is to secure Group Life and Funeral cover.</p>
            <p className="blue-label">1.4 Consent and declaration</p>
            <p>I, the Policy Holder of this policy, hereby give consent to the Processing of my Personal Information in terms of POPIA for all
              purposes of maintaining this insurance product and consent to the terms of the Privacy Notice and the PAIA manual located on
              our website https://www.shieldlife.co.za. I understand that the supply of this information is mandatory and without the supply of
              the information this agreement will be will be invalid. Shield Life will process and protect my personal information in terms of the
              required aspects of POPIA.</p>
            <p>I hereby give consent that Shield Life may request further processing of my personal information from a third party (Premium
              collection agency, Credit bureau etc.) for the purposes of maintaining my insurance product. I hereby agree that I have the appr
              opriate authorization of supplying special / sensitive / personal information of data subjects other than myself.
              I hereby agree that all the information supplied in this agreement is accurate and complete and should any of this information
              change I will contact Shield Life Ltd to update my personal information.</p>
            <div>
              {!code || (!code.match('sll') && !code.match('tsll')) ?
                <p> I hereby consent that my personal information may be used for marketing purposes with the Maxi Forces Financial Services and Shield Life Insurance Product range.</p>
                : <p>I hereby consent that my personal information may be used for marketing purposes within the Shield Life Insurance Product range.</p>
              }
              <span className="d-flex align-items-center justify-content-end me-3">
                <Form.Check
                  type="checkbox"
                  checked={addInfo && addInfo.sendInfo === "yes"}
                  id="check1"
                  label="Yes"
                  disabled>
                </Form.Check>

                <Form.Check
                  disabled
                  type="checkbox"
                  id="check2"
                  label="No"
                  checked={addInfo && addInfo.sendInfo === "No"} className="ms-3">
                </Form.Check>
              </span>
            </div>
            <p>By accepting the Terms and Conditions, I give consent that Shield Life may request further processing of my personal information from a third party (Premium
              collection agency, Credit bureau etc.) for the purposes of maintaining my insurance product. I hereby agree that I have the appr
              opriate authorization of supplying special / sensitive / personal information of data subjects other than myself.</p>
            <p>I hereby agree that all the information supplied in this agreement is accurate and complete and should any of this information
              change I will contact Shield Life Ltd to update my personal information.</p>
            <p>By accepting the Terms and Conditions, I understand that without the above consent my policy will not be processed and Shield Life will not be liable
              for any claim that may arise. I understand that the cover will only start when my application has been accepted and I have paid
              the first premium. I authorise the Insurer to communicate with me regarding my policy via Short Message System (SMS); Whats
              App; and/or email.</p>

            <h6 className="mt-3">2. Statutory Notice</h6>
            <p>The notice does not form part of the Insurance Contract nor any other document, but you have the right to the following information.</p>
            <p className="blue-label">Details about other parties involved:</p>

            <table className="table" v-align="top">
              {!code || (!code.match('sll') && !code.match('tsll')) ?
                <tr>
                  <td v-align="top">
                    &nbsp;
                  </td>
                  <td className="blue-label" v-align="top">
                    Broker details:
                  </td>
                  <td className="blue-label" v-align="top">
                    Insurer details:
                  </td>
                </tr> : <tr>
                  <td v-align="top">
                    &nbsp;
                  </td>
                  <td className="blue-label" v-align="top">
                    The Insurer and Broker
                  </td>
                  <td v-align="top">
                    &nbsp;
                  </td>
                </tr>
              }

              {!code || (!code.match('sll') && !code.match('tsll')) ?
                <>
                  <tr>
                    <td v-align="top">
                      Name:
                    </td>
                    <td v-align="top">
                      Maxi Forces Financial Services (Pty) Ltd
                    </td>
                    <td v-align="top">
                      Shield Life® Limited
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Authorised FSP No:
                    </td>
                    <td v-align="top">
                      1966
                    </td>
                    <td v-align="top">
                      47477
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Registration no:
                    </td>
                    <td v-align="top">
                      1995/004244/07
                    </td>
                    <td v-align="top">
                      2016/249174/06
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Tel no:
                    </td>
                    <td v-align="top">
                      0861 222 303
                    </td>
                    <td v-align="top">
                      {code && code.match('scs') ? '0861 113 112' : '0861 222 303'}
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Email:
                    </td>
                    <td v-align="top">
                      info@maxiforces.co.za
                    </td>
                    <td v-align="top">
                      css@shieldlife.co.za
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Website:
                    </td>
                    <td v-align="top">
                    </td>
                    <td v-align="top">
                      www.shieldlife.co.za
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Physical Address:
                    </td>
                    <td v-align="top">
                      1293 South Street,
                      Maxi Building,
                      Centurion,
                      0157
                    </td>
                    <td v-align="top">
                      1293 South Street,
                      Maxi Building,
                      Centurion,
                      0157
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Postal Address:
                    </td>
                    <td v-align="top"></td>
                    <td v-align="top">
                      PO Box 15473
                      Lyttleton,
                      0140
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Authorised Category I products:
                    </td>
                    <td v-align="top">Long-term Insurance: A, B1, B1-A, B2, B2-A
                      Short-term Insurance: Personal Lines (PL), PL
                      A1</td>
                    <td v-align="top">
                      Long-term Insurance: A, B1, B1-A
                      Short-term Insurance: Personal Lines
                      (PL), PL A1 Participatory interest in a
                      collective investment scheme
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Compliance officer:
                    </td>
                    <td v-align="top">Moonstone Compliance (Pty) Ltd represented by
                      Ms Géta Hancke
                      Tel: (021) 883 8000
                      Fax: (021) 880 0688
                      E-mail: ghancke@moonstone.co.za
                      PO Box 12662, Die Board, Stellenbosch,7613</td>
                    <td v-align="top">
                      The Compliance Department can be
                      access through our website
                      www.shieldlife.co.za or e-mail
                      compliance@shieldlife.co.za
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Complaints:
                    </td>
                    <td v-align="top">Must be in writing and will be attended to
                      within 10 business days. We may request
                      additional information, if needed and will
                      keep the complainant up to date of the progress
                      made. If the complaint is not resolved to the
                      satisfaction of the complainant, they may approach
                      the FAIS Ombud after expiry of the 90 day
                      period. For further details, our Complaints
                      Management Policy is available upon request:
                      complaints@maxiforces.co.za</td>
                    <td v-align="top">
                      The Compliance Policy can be access
                      through our website
                      www.shieldlife.co.za or e-mail
                      complaints@shieldlife.co.za
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Legal & contractual relationship:
                    </td>
                    <td v-align="top">Intermediary Service Agreement</td>
                    <td v-align="top">
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Professional Indemnity Insurance:
                    </td>
                    <td v-align="top">Professional Indemnity Cover is in place.</td>
                    <td v-align="top">
                      Professional Indemnity Cover & Fidelity
                      Cover are in place.
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Conflict of interest:
                    </td>
                    <td v-align="top">The Conflict of Interest Management Policy
                      can be obtained upon written request:
                      compliance@maxiforces.co.za</td>
                    <td v-align="top">
                      The Conflict of Interest Policy can be
                      obtained upon written request from the
                      compliance officer.
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Life insurer aprroved class and
                      sub-classes of business:
                    </td>
                    <td v-align="top"></td>
                    <td v-align="top">
                      Risk: Individual and Group Death,
                      Individual and Group Disability-Lump
                      sum Funeral: Individual, Group
                      Credit Life
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Claims procedure:
                    </td>
                    <td v-align="top"></td>
                    <td v-align="top">
                      All claims enquiries should be address
                      to: The Claims Department: Shield Life
                      PO Box 7309, Centurion, 0046
                      Tel: 0861 277 873
                      Email: claims@shieldlife.co.za
                    </td>
                  </tr>
                </> :
                <>
                  <tr>
                    <td v-align="top">
                      Name:
                    </td>
                    <td v-align="top">
                      Shield Life® Limited
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Authorised FSP No:
                    </td>
                    <td v-align="top">
                      47477
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Registration no:
                    </td>
                    <td v-align="top">
                      2016/249174/06
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Tel no:
                    </td>
                    <td v-align="top">
                      0861 777 353
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Email:
                    </td>
                    <td v-align="top">
                      css@shieldlife.co.za
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Website:
                    </td>
                    <td v-align="top">
                      www.shieldlife.co.za
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Physical Address:
                    </td>
                    <td v-align="top">
                      1293 South Street,
                      Maxi Building,
                      Centurion,
                      0157
                    </td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Postal Address:
                    </td>
                    <td v-align="top">PO Box 7309,
                      Centurion,
                      0157</td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Authorised Category I products:
                    </td>
                    <td v-align="top">Long-term Insurance: A, B1, B1-A
                      Short-term Insurance: Personal Lines
                      (PL), PL A1 Participatory interest in a
                      collective investment scheme</td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Compliance officer:
                    </td>
                    <td v-align="top">The Compliance Department can be
                      access through our website
                      www.shieldlife.co.za or e-mail
                      compliance@shieldlife.co.za</td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Complaints:
                    </td>
                    <td v-align="top">The Compliance Policy can be access
                      through our website
                      www.shieldlife.co.za or e-mail
                      complaints@shieldlife.co.za</td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      FAIS Compliance officer:
                    </td>
                    <td v-align="top">Syncerus Business Solutions (Pty) Ltd
                      represented by: Ms Riana Steyn
                      Tel: (082) 307 2287
                      E-mail: riana@syncerus.co.za
                      PO Box 2583, Brooklyn Square, 0075</td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Professional Indemnity Insurance:
                    </td>
                    <td v-align="top">Professional Indemnity Cover & Fidelity
                      Cover are in place.</td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Conflict of interest:
                    </td>
                    <td v-align="top">The Conflict of Interest Policy can be
                      obtained upon written request from the
                      compliance officer.</td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Life insurer aprroved class and
                      sub-classes of business:
                    </td>
                    <td v-align="top">Risk: Individual and Group Death,
                      Individual and Group Disability-Lump
                      sum Funeral: Individual, Group
                      Credit Life</td>
                  </tr>
                  <tr>
                    <td v-align="top">
                      Claims procedure:
                    </td>
                    <td v-align="top">All claims enquiries should be address
                      to: The Claims Department: Shield Life
                      PO Box 7309, Centurion, 0046
                      Tel: 0861 277 873
                      Email: claims@shieldlife.co.za</td>
                  </tr>
                </>}
            </table>
            <p className="blue-label mt-2">Other important contact details:</p>
            <div>
              <b>The FAIS Ombud (For advice/policy related matters):</b>
              <p>The FAIS Ombud
                PO Box 74571, Lynnwood Ridge, 0040
                Tel: 086 066 3247 or Tel: (012) 762 5000
                Fax: (012) 348 3447
                Email: info@faisombud.co.za
                www.faisombud.co.za</p>
              <b>The Long-term Insurance Ombud (For Claims/service related matters):</b>
              <p>Ombudsman for Long-term Insurance
                Claremont Central Building, 6th Floor, 6
                Vineyard Road, Claremont, 7700
                Tel: 0860 103 236 or (021) 657 5000
                Fax: (021) 674 0951
                Email: info@ombud.co.za
                www.ombud.co.za</p>
              <b>The Authority (For market conduct matters):</b>
              <p>For Complaints on the Insurer that are not resolved to
                your satisfaction by the product supplier, please
                contact: The Financial Sector Conduct Authority
                PO Box 35655, Menlo Park, 0102
                Tel: (012) 428 8000, Fax: (012) 347 0221
                Email: info@fsca.co.za</p>
              <b>The Information Regulator (South Africa) (For POPI matters):</b>
              <p>PO Box 31533, Braamfontein, Johannesburg, 2017
                Email: inforeg@justice.gov.za
                www.justice.gov.za/inforeg/docs1-gn.html#gn-paia</p>
            </div>
            <p className="blue-label mt-2">Important information:</p>
            <table className="table" v-align="top">
              <tr>
                <td v-align="top">
                  Premium obligations:
                </td>
                <td v-align="top">The obligations that you assume as premium payer is indicated in the Insurance Contract.
                  Ensure that you are fully aware of these obligations.</td>
              </tr>
              <tr>
                <td v-align="top">
                  Premium payments:
                </td>
                <td v-align="top">The conditions of the Insurance Contract set out the due date of premiums and the
                  consequences of non-payment of such premiums.</td>
              </tr>
              <tr>
                <td v-align="top">
                  Commencement of Insurance:
                </td>
                <td v-align="top">Cover will be active from the first day of the month in which we receive the first premium.
                  Premiums are payable monthly. A period of thirty (30) days grace is allowed for payment
                  of each premium due and payable.</td>
              </tr>
              <tr>
                <td v-align="top">
                  Cooling of Rights:
                </td>
                <td v-align="top">Cooling off rights may be exercised within 31 days after inception of the membership,
                  where no benefit has been paid or claimed. A written cancellation form must be completed
                  and send to Shield Life® Ltd. Cooling off rights can not be exercised if other legislation
                  prohibit it</td>
              </tr>
              <tr>
                <td v-align="top">
                  Expiry of Insurance cover:
                </td>
                <td v-align="top">All benefits and premiums will cease to be payable upon the events as described in the
                  policy wording.</td>
              </tr>

              <tr>
                <td v-align="top">
                  Cancellation by Insurer:
                </td>
                <td v-align="top">If payment of premium is unsuccessful, the policy benefits are at risk and it is the
                  responsibility of the Insured to notify the Insurer as to how to collect arrear premiums.
                  If the Insured don’t, the Insurer may cancel the policy and the Insured will lose the
                  cover and all premiums.</td>
              </tr>
              <tr>
                <td v-align="top">
                  Premium & Benefit increase:
                </td>
                <td v-align="top">Policy & Benefit increase are subject to decisions made by Shield Life® Limited.</td>
              </tr>
              <tr>
                <td v-align="top">
                  Remuneration:
                </td>
                <td v-align="top">Commission will not exceed the statutory commission as defined by the Life Insurance Act.</td>
              </tr>
              <tr>
                <td v-align="top">
                  Information provided:
                </td>
                <td v-align="top">Decisions made by Shield Life® Ltd as the insurer, in respect of insurance or claim, may
                  be influenced by the failure to provide correct or full information by the insured.</td>
              </tr>
              <tr>
                <td v-align="top">
                  Insurance Contract understanding:
                </td>
                <td v-align="top">Ensure you understand what benefits and exclusions are applicable to your Insurance
                  Contract. Make sure you are acquainted with:
                  1. what is not covered,
                  2. what is restricted and
                  3. If there are any special clauses.</td>
              </tr>
              <tr>
                <td v-align="top">
                  Important information:
                </td>
                <td v-align="top">You will be informed of any material changes to the above mentioned information.
                  This disclosure notice serves to provide you with the information in writing if any
                  information was given to you orally.
                  The reason for your claim to be repudiated will always be communicated to you in writing.
                  In case of the insurer cancelling your policy, this will be done in writing to your last known
                  address You will always be entitled to a copy of your policy, free of charge.
                  <br />
                  For instructions to access, verify, or object to personal information processed by Shield
                  Life, please view our privacy notice on www.shieldlife.co.za
                  Should any of my dependants or I have any concerns about the processing of our personal
                  information, we may raise the matter with Shield Life's Information Officer at
                  privacy@shieldlife.co.za
                </td>
              </tr>
            </table>
            {(!code || (!code.match('sll') && !code.match('tsll'))) && <>
              <h6 className="blue-label">Treating Customers Fairly (TCF) Principles</h6>
              <b>Shield Life Ltd and Maxi Forces Financial Services subscribe to the following 6 TCF principles during product creation to
                meet your requirements and expectations:</b>
              <table className="table mb-3" v-align="top">
                <tr>
                  <td v-align="top">
                    Culture
                  </td>
                  <td v-align="top">
                    Consumers are confident that fair treatment is central to our Company's culture
                  </td>
                </tr>
                <tr>
                  <td v-align="top">
                    Right Product
                  </td>
                  <td v-align="top">
                    Consumers have Products & Services that meet their needs
                  </td>
                </tr>
                <tr>
                  <td v-align="top">
                    Informed
                  </td>
                  <td v-align="top">
                    Consumers received communication that is clear and in understandable language
                  </td>
                </tr>
                <tr>
                  <td v-align="top">
                    Suitable Advice
                  </td>
                  <td v-align="top">
                    Consumers receive suitable advice aimed at their circumstances
                  </td>
                </tr>
                <tr>
                  <td v-align="top">
                    Meet Expectations
                  </td>
                  <td v-align="top">
                    Consumers receive Products & Services that will meet their expectations
                  </td>
                </tr>
                <tr>
                  <td v-align="top">
                    Excellent Service
                  </td>
                  <td v-align="top">
                    Consumers experience no post-sale barriers
                  </td>
                </tr>
              </table>
            </>}
            <p className="mb-2 mt-2">
              <strong>Additional information:</strong>
              <p>Without in any way limiting and subject to the other provisions of the Services Agreement/Mandate, Maxi Forces Financial Services (Pty) Ltd accepts responsibility for the lawful actions of their representatives (as defined in the Financial Advisory and Intermediary Service Act) in rendering financial services within the course and scope of their employment.  All our representatives are authorised representatives of Maxi Forces Financial Services (Pty) Ltd and some representatives may be rendering services under supervision and will inform you accordingly.</p>
              <p>The main insured and spouse will be covered in the event of Death, Accidental Death, Income Booster and Terminal Illness where applicable.  Children and Extended family will be covered in the event of Death, where applicable.</p>
              <p>In return for payment of a monthly, your cover amount will be paid based on the following:</p>
              <ul>
                <li>The insured event occurs within the period of membership;</li>
                <li>The event giving rise to a claim is covered in terms of the exclusions and/or the terms and conditions of the policy wording;</li>
                <li>The truth and accuracy of the information at the time of application;</li>
                <li>You provide us with all the required documents as set on the claim form provided; and</li>
                <li>The claim is reported within the prescribed periods stipulated in your policy wording."</li>
              </ul>
              <p>The benefit payable will be based on the following:</p>
              <ul>
                <li>In the event of Death, due to accidental, violent, external and visible means, the benefit will be payable to the nominated beneficiary.</li>
                <li>In the event of Death, due to natural causes, after the expiry of the initial waiting period, the benefit will be payable to the main member or nominated beneficiary."</li>
              </ul>
            </p>
            <p className="mb-2">
              <strong>Surrender Value:</strong>
              <p>The Funeral cover and all benefits that is included in the Funeral plan has no surrender, loan or paid up values.</p>
            </p>
            <p className="mb-2">
              <strong>Exclusions:</strong>
              <p>Claims will not be paid if: Any loss or expense of whatsoever nature directly caused by, resulting from, or in connection with the willing participation by the Principal Member and/or Dependant in any of the following against the lawful military or police forces of South Africa: Civil War; Revolution; Insurrection; Invasion; Overthrow of the legally constituted government; Military or usurped power and Civil commotion assuming the proportions of, or amounting to, an uprising against an established government.</p>
              <p>This Funeral plan also excludes any loss or expense of whatsoever nature directly caused by, resulting from in connection with any of the following:</p>
              <p>1. Use of nuclear, biological or chemical weapons, or any radioactive contamination;</p>
              <p>2. Retrieving data. Wait a few seconds and try to cut or copy again.</p>
              <p>3. The member committing any act or deed in violation of criminal or military law;</p>
              <p>4. Martial law or loot, sack or pillage therewith;</p>
              <p>5. Confiscation or nationalisation or requisition or destruction of or damage to property by or under the order of any government or public or local authority.</p>
              <p>6. Intentional self – inflicted injury, suicide or a suicide attempt (whether sane or insane) within 12 months from the Entry Date;</p>
              <p>7. Discontinuance of the payment of premiums or part thereof in respect of a Principal Member.</p>
              <p className="mb-2">Full Exclusions available in your Policy Document which will be sent to you.</p>
              <strong>Waiting period is only applicable to new additions or the increased benefit amount of existing membership starting from the endorsement date:</strong>

              <table className="table cust-table">
                <tr>
                  <th>Cover</th>
                  <th>Waiting period</th>
                </tr>
                <tr>
                  <td>Funeral Cover (if no previous waiting periods have been completed):</td>
                  <td>Natural Causes - 6 months from first successful premium collection; Suicide - 12 months from first successful premium collection</td>
                </tr>
                <tr>
                  <td>Accidental Death:	</td>
                  <td>Immediately from first successful premium collection</td>
                </tr>
              </table>
            </p>
          </div>}

          <Footer>
            <ul className="btn-list">
              <li className="back-li">
                <BtnCompo
                  buttonAction={"/add-info"}
                  buttonIcon={"/images/icon-back.png"}
                  buttonClass={"btn-back"}
                  buttonIconHeight={22}
                  buttonIconWidth={27}
                />
              </li>
              <li>
                <BtnCompo
                  buttonAction={"/rather-call-me"}
                  buttonText={"RATHER CALL ME"}
                  buttonOutline={true}
                  buttonClass={"btn-rather"}
                />
              </li>
              <li>
                <Button variant="primary" type="submit" className="terms-text">
                  I Accept the Terms of this Agreement
                </Button>
              </li>
            </ul>
          </Footer>
        </Form>
      </div>
    </Container>
  );
};

export default SignConfirm;
