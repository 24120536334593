import ImgCompo from "components/img-compo";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const RatherCallMe = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let profile; let profileAddress; let employeeDetails; let beneficiary; let addInfo; let checkInfoData; let totalCost; let representativeData; let bankingDetailsDO; let selfCoverData; let mainId; let extendedFamily; let signature;
    let deviceInfo;

    if (localStorage.getItem("profile")) profile = JSON.parse(localStorage.getItem("profile"));

    if (localStorage.getItem("profileAddress")) profileAddress = JSON.parse(localStorage.getItem("profileAddress"));

    if (localStorage.getItem("employeeDetails")) employeeDetails = JSON.parse(localStorage.getItem("employeeDetails"));

    if (localStorage.getItem("beneficiary")) beneficiary = JSON.parse(localStorage.getItem("beneficiary"));

    if (localStorage.getItem("addInfo")) addInfo = JSON.parse(localStorage.getItem("addInfo"));

    if (localStorage.getItem("checkInfoData")) checkInfoData = JSON.parse(localStorage.getItem("checkInfoData"));

    if (localStorage.getItem("totalCost")) totalCost = JSON.parse(localStorage.getItem("totalCost"));

    if (localStorage.getItem("representativeData")) representativeData = JSON.parse(localStorage.getItem("representativeData"));

    if (localStorage.getItem("bankingDetailsDO")) bankingDetailsDO = JSON.parse(localStorage.getItem("bankingDetailsDO"));

    if (localStorage.getItem("MainId")) mainId = JSON.parse(localStorage.getItem("MainId"));

    if (localStorage.getItem("selfCoverData")) selfCoverData = JSON.parse(localStorage.getItem("selfCoverData"));

    if (localStorage.getItem("extendedFamily")) extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));

    if (localStorage.getItem("signature")) signature = JSON.parse(localStorage.getItem("signature"));

    if (localStorage.getItem("deviceInfo")) deviceInfo = JSON.parse(localStorage.getItem("deviceInfo"));

    let payload = {
      sourceData: "funeralApp",
      csFuneralApp: "Yes",
      RatherCall: "yes",
      addInfo,
      bankingDetailsDO,
      extendedFamily,
      representativeData,
      signature,
      deviceInfo,
      selfCoverData,
      ...profile,
      ...profileAddress,
      ...checkInfoData,
      ...totalCost,
      ...mainId,
      ...selfCoverData,
      ...beneficiary,
      ...employeeDetails,
      SourceSite: "csfuneral.co.za",
    };
    var config = {
      method: "post",
      url: "https://apiv2.msgl.ink/",
      headers: {
        "content-type": "application/json",
      },
      data: {
        ...payload,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        window.location.reload(true);
      });

  }, [])

  return (
    <Container className="h-100">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Application, please wait...</div>
      </div>}
      <div className="main-content text-center">
        <h1 className="page-title m-lg-b">SORRY TO SEE YOU GO</h1>
        <div className="content-block">
          <div className="content-text">
            <p>
              Thank you, one of our consultants will be in touch with you
              shortly.
            </p>
          </div>
          <div className="content-logo">
            <ImgCompo
              src="/images/logo.png"
              alt="Shield Life Funeral"
              className="mt-auto img-fluid"
              width={224}
              height={224}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RatherCallMe;
