import React, { useEffect, useState } from "react";
import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import Slider from "components/slider";
import axios from "axios";
import ImgCompo from "components/img-compo";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const ExtendedFamilyCover = () => {
  const [validated, setValidated] = useState(false);
  const [memberRelation, setMemberRelation] = useState("");
  const [relationshipData, setRelationshipData] = useState([]);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [relationship, setRelationship] = useState("");
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [showError, setShowError] = useState(false);
  const [ageError, setAgeError] = useState("");

  const [coverData, setCoverData] = useState([]);
  const [defaultChecked, setDefaultChecked] = useState(false);
  const [disabledFuneral, setDisabledFuneral] = useState(true);
  const [disabledCheckbox, setDisabledCheckbox] = useState();
  const [benefit, setBenefit] = useState(0);
  const [premium, setPremium] = useState(0);

  const [spouseCoverData, setSpouseCoverData] = useState([]);
  const [accidentalDefaultChecked, setAccidentalDefaultChecked] = useState(false);
  const [disabledAccidental, setDisabledAccidental] = useState(true);
  const [disabledAccidentalCheckbox, setDisabledAccidentalCheckbox] = useState();
  const [accidentalAmount, setAccidentalAmount] = useState(0);
  const [accidentalCost, setAccidentalCost] = useState(0);

  const [incomeDefaultChecked, setIncomeDefaultChecked] = useState(false);
  const [disabledIncome, setDisabledIncome] = useState(true);
  const [disabledIncomeCheckbox, setDisabledIncomeCheckbox] = useState();
  const [incomeAmount, setIncomeAmount] = useState(0);
  const [incomeCost, setIncomeCost] = useState(0);

  const [availableCost, setAvailableCost] = useState(100000);
  const [illnessBenefits, setIllnessBenefits] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const [selfCoverData, setSelfCoverData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const [data, setData] = useState();
  const [type, setType] = useState();
  const [isCoverSelect, setISCoverSelect] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setIndex(queryParams.get("index") || null);
    setMemberRelation(queryParams.get("cover") || null);
  }, []);

  useEffect(() => {
    axios
      .get("./relationship.json")
      .then((res) => {
        let data = res.data;
        data.forEach((item) => {
          if (memberRelation === item.value) {
            setTitle(item.name);
            setRelationshipData(item.relation);
          }
        });
      })
      .catch((err) => console.log(err));

    if (memberRelation) {
      axios
        .get(`./${memberRelation}.json`)
        .then((res) => {
          if (res.data) setCoverData(res.data);
        })
        .catch((err) => console.log(err));
    }

    if (memberRelation === 'spouse') {
      axios
        .get(`./spouseCover.json`)
        .then((res) => {
          if (res.data) setSpouseCoverData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [memberRelation, index]);

  useEffect(() => {
    if (localStorage.getItem("selfCoverData")) {
      const selfCover = JSON.parse(localStorage.getItem("selfCoverData"));
      setSelfCoverData(selfCover);
    }

    if (localStorage.getItem("extendedFamily")) {
      if (localStorage.getItem("indexPage") && parseInt(localStorage.getItem("indexPage")) > parseInt(index)) {
        const extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
        setMemberRelation(extendedFamily[index].memberRelation);
        setName(extendedFamily[index].name);
        setSurname(extendedFamily[index].surname);
        setDateOfBirth(extendedFamily[index].dateOfBirth);
        setAge(extendedFamily[index].age);
        setGender(extendedFamily[index].gender);
        setRelationship(extendedFamily[index].relationship);
        setBenefit(extendedFamily[index].benefit);
        setPremium(extendedFamily[index].premium);
        if (extendedFamily[index].spouseAccidentalAmount) setAccidentalAmount(extendedFamily[index].spouseAccidentalAmount);
        if (extendedFamily[index].spouseAccidentalCost) setAccidentalCost(extendedFamily[index].spouseAccidentalCost);
        if (extendedFamily[index].spouseIncomeAmount) setIncomeAmount(extendedFamily[index].spouseIncomeAmount);
        if (extendedFamily[index].spouseIncomeCost) setIncomeCost(extendedFamily[index].spouseIncomeCost);
        if (extendedFamily[index].spouseIllnessBenefits) setIllnessBenefits(extendedFamily[index].spouseIllnessBenefit);
        setIsUpdate(true);
      }
    }
  }, [index]);

  useEffect(() => {
    if (memberRelation === 'spouse') {
      let cost = Number(benefit) + Number(accidentalAmount) + Number(incomeAmount * 3);
      setAvailableCost(100000 - Number(cost));
    }
  }, [memberRelation, benefit, accidentalAmount, incomeAmount]);

  useEffect(() => {
    let total = premium + accidentalCost + incomeCost;
    setIllnessBenefits(benefit * 20 / 100);
    setTotalCost(parseFloat(total).toFixed(2));
  }, [accidentalCost, benefit, incomeCost, premium]);

  const changeSelection = (e, value) => {
    if (value === "funeral") {
      if (e === true) {
        setDefaultChecked(true);
        setDisabledFuneral(false);
        setType('funeral');
        setISCoverSelect(true);
        handleShow();
      } else {
        setDefaultChecked(false);
        setDisabledFuneral(true);
        setBenefit(0);
        setPremium(0);
      }
    }
    if (value === "accidental") {
      if (e === true) {
        setAccidentalDefaultChecked(true);
        setDisabledAccidental(false);
        setType('accidental');
        setISCoverSelect(true);
        handleShow();
      } else {
        setAccidentalDefaultChecked(false);
        setDisabledAccidental(true);
        setAccidentalCost(0);
        setAccidentalAmount(0);
      }
    }
    if (value === "income") {
      if (e === true) {
        setIncomeDefaultChecked(true);
        setDisabledIncome(false);
        setType('income');
        setISCoverSelect(true);
        handleShow();
      } else {
        setIncomeDefaultChecked(false);
        setDisabledIncome(true);
        setIncomeCost(0);
        setIncomeAmount(0);
      }
    }
  };

  const changeDOB = (value) => {
    setDefaultChecked(false);
    setAccidentalDefaultChecked(false);
    setIncomeDefaultChecked(false);
    const actualValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    if (actualValue.length === 8) {
      setDateOfBirth(value);
      getAge(value);
    }
  };

  const getAge = (dateString) => {
    var now = new Date();
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var dob = new Date(dateString);
    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearAge = "";

    yearAge = yearNow - yearDob;
    var monthAge;
    if (monthNow >= monthDob) monthAge = monthNow - monthDob;
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }

    var dateAge;
    if (dateNow >= dateDob) dateAge = dateNow - dateDob;
    else {
      monthAge--;
      dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    if (dateAge === 0) dateAge = 1;
    if (monthAge === 0) monthAge = 1;

    age = { years: yearAge, months: monthAge, days: dateAge };

    if (age.years > 0 && age.months > 0 && age.days > 0) ageString = age.years;
    else if (age.years === 0 && age.months > 0 && age.days > 0) ageString = 1;

    setAge(ageString);
    clearData();
    validateCoverAge(ageString);
  };

  const clearData = () => {
    setDefaultChecked(false);
    setDisabledFuneral(true);
    setPremium(0);
    setBenefit(0);

    setAccidentalDefaultChecked(false);
    setDisabledAccidental(true);
    setAccidentalCost(0);
    setAccidentalAmount(0);

    setIncomeDefaultChecked(false);
    setDisabledIncome(true);
    setIncomeCost(0);
    setIncomeAmount(0);
  }

  const validateCoverAge = (ageString) => {
    coverData.forEach((item) => {
      let data = item.allCost.map((ageCost) => {
        if (ageString <= ageCost.agemax && ageString >= ageCost.agemin)
          return true;
        else return false;
      });
      if (data.includes(true)) {
        setDisabledCheckbox(false);
        setDisabledIncomeCheckbox(false);
        setDisabledAccidentalCheckbox(false);
        setAgeError("");
      } else {
        setDisabledCheckbox(true);
        setAgeError(`No Benefits available for this age`);
        setDisabledIncomeCheckbox(true);
        setDisabledAccidentalCheckbox(true);
      }
    });
  }

  const changeCoverAmount = (coverName, e) => {
    setShowError(false);
    localStorage.removeItem("totalCost");
    const actualValue = age.toString().length;
    if (actualValue <= 2) {
      if (coverName === "funeral") {
        coverData.forEach((item) => {
          if (item.amount === e) {
            item.allCost.forEach((ageCost) => {
              if ((age <= ageCost.agemax && age >= ageCost.agemin) && selfCoverData.funeralAmount >= Number(e) && ageCost.cost > 0) {
                setPremium(ageCost.cost);
                setBenefit(e);
              }
            });
          }
        });
      } else {
        spouseCoverData.forEach((spouse) => {
          spouse.benefits.forEach((item) => {
            if (item.amount === e) {
              item.allCost.forEach((ageCost) => {
                if (spouse.coverName === coverName && age <= ageCost.agemax && age >= ageCost.agemin) {
                  if (coverName === "accidental" && benefit >= e && selfCoverData.accidentalAmount >= Number(e)) {
                    setAccidentalCost(ageCost.cost);
                    setAccidentalAmount(e);
                  }
                  if (coverName === "income" && selfCoverData.incomeAmount >= Number(e)) {
                    setIncomeCost(ageCost.cost);
                    setIncomeAmount(e);
                  }
                }
              });
            }
          });
        });
      }
    }
  };

  useEffect(() => {
    if (approved) {
      setIsLoading(true);
      let representativeData; let profile; let indexPage; let deviceInfo
      if (localStorage.getItem("representativeData")) representativeData = JSON.parse(localStorage.getItem("representativeData"));
      if (localStorage.getItem("profile")) profile = JSON.parse(localStorage.getItem("profile"));
      if (localStorage.getItem("indexPage")) indexPage = JSON.parse(localStorage.getItem("indexPage"));
      if (localStorage.getItem("deviceInfo")) deviceInfo = JSON.parse(localStorage.getItem("deviceInfo"));
      let extendedFamilyArray = JSON.parse(localStorage.getItem("extendedFamily"));

      let payload = {
        page: 'extended-family-selection',
        sourceData: "funeralApp",
        representativeData,
        profile,
        selfCoverData,
        extendedFamilyArray,
        indexPage,
        deviceInfo
      }

      var config = {
        method: "post",
        url: "https://apiv2.msgl.ink/",
        headers: {
          "content-type": "application/json",
        },
        data: {
          ...payload,
        },
      };
      axios(config)
        .then(function (response) {
          setIsLoading(false);
          if (data === "next") navigate("/quote-summary");
          else navigate("/extended-family-selection");
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }

  }, [approved, data, navigate, selfCoverData])

  const handleSubmit = (event, value) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (name && surname && dateOfBirth && age && gender && benefit && premium && relationship) {
      let extendedFamily = {};

      extendedFamily = {
        name: name,
        surname: surname,
        dateOfBirth: dateOfBirth,
        age: age,
        gender: gender,
        relationship: relationship,
        benefit: benefit,
        premium: premium,
        id: index,
        memberRelation: memberRelation
      };

      if (memberRelation === 'spouse') {
        extendedFamily = {
          ...extendedFamily,
          spouseAccidentalAmount: accidentalAmount,
          spouseAccidentalCost: accidentalCost,
          spouseIncomeAmount: incomeAmount,
          spouseIncomeCost: incomeCost,
          spouseIllnessBenefits: illnessBenefits,
          spouseTotalCost: totalCost
        }
      }

      var idx = 1 + Number(index);
      setIndex(idx);
      if (!isUpdate) storeID(idx);

      let extendedFamilyArray = localStorage.getItem("extendedFamily")
        ? JSON.parse(localStorage.getItem("extendedFamily"))
        : [];
      isUpdate
        ? (extendedFamilyArray[index] = extendedFamily)
        : extendedFamilyArray.push(extendedFamily);

      localStorage.removeItem("extendedFamily");
      localStorage.setItem(
        "extendedFamily",
        JSON.stringify(extendedFamilyArray)
      );


    } else if (!name || !surname || !age || !gender || !relationship) {
      if (!benefit || !premium) setShowError(true);
      setValidated(true);
    }
  };

  const storeID = (idx) => {
    localStorage.setItem("indexPage", idx);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    if (memberRelation !== 'parents') setShow(true);
  };

  return (
    <Container className="h-100">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Data, please wait...</div>
      </div>}
      <div className="main-content">
        <h1 className="page-title mb-4">TELL US A LITTLE ABOUT YOUR {title.toUpperCase()}!</h1>
        <Form noValidate validated={validated}>
          <Row className="mb-5 g-3">
            <Form.Group as={Col} md="12">
              <Form.Label htmlFor="name">First Name *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-user.png"
                    alt="FirstName"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="First Name"
                  aria-describedby="inputGroupPrepend"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Form.Control.Feedback type="valid">
                  FirstName is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please Enter a FirstName.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label htmlFor="surname">Surname *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-user.png"
                    alt="Surname"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="Surname"
                  aria-describedby="inputGroupPrepend"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
                <Form.Control.Feedback type="valid">
                  Surname is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please Enter a Surname.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="6">
              <Form.Label htmlFor="dateOfBirth">Date Of Birth *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-birthdate.png"
                    alt="DateOfBirth"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="date"
                  placeholder="DateOfBirth"
                  aria-describedby="inputGroupPrepend"
                  onChange={(e) => changeDOB(e.target.value)}
                  value={dateOfBirth}
                />
                <Form.Control.Feedback type="valid">
                  Date Of Birth is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Date Of Birth.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="6">
              <Form.Label htmlFor="age">Age</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-age.png"
                    alt="Age"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Age"
                  aria-describedby="inputGroupPrepend"
                  value={age}
                  disabled
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label htmlFor="gender">Gender *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-gender.png"
                    alt="Gender"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Select
                  required
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  aria-describedby="inputGroupPrepend"
                  aria-label="Gender"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Form.Select>

                <Form.Control.Feedback type="valid">
                  Gender is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Gender.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label htmlFor="emailAddress">Relationship *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-relation.png"
                    alt="Gender"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>

                <Form.Select
                  required
                  aria-describedby="inputGroupPrepend"
                  aria-label="Relationship"
                  value={relationship}
                  onChange={(e) => { setRelationship(e.target.value); handleShow(); setISCoverSelect(false); }}
                >
                  <option value="">Select Relationship</option>
                  {relationshipData.map(({ type }) => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </Form.Select>

                <Form.Control.Feedback type="valid">
                  Relationship is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Relationship.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <div>
            {showError && (
              <div className="text-center text-sm" style={{ color: "red" }}>
                <p>Please select the Amount of Funeral Cover</p>
              </div>
            )}
            {ageError && (
              <div className="text-center text-sm" style={{ color: "red" }}>
                <p>{ageError}</p>
              </div>
            )}
          </div>

          <h2 className="title-sm text-primary text-center">
            {relationship} COVER SELECTION
          </h2>
          <div className="text-center text-sm">
            <p>PLEASE SELECT THE AMOUNT OF COVER REQUIRED.</p>
            <p>
              *NOTE - MAXIMUM COVER ACROSS PRODUCTS IS LIMITED TO R100 000.00
            </p>
          </div>
          <div className="cover-list">
            <div className="cover-list-item">
              <div className="cover-list-input">
                <input
                  disabled={disabledCheckbox}
                  checked={defaultChecked}
                  className="form-check-input"
                  type="checkbox"
                  value="Funeral Cover"
                  id="cover1"
                  onChange={(e) => changeSelection(e.target.checked, "funeral")}
                />
              </div>
              <div className="cover-list-slider-container">
                <div className="cover-list-title">Funeral Cover</div>
                <div className="cover-list-slider">
                  <Slider
                    min={0}
                    max={memberRelation === 'spouse' ? 100000 : memberRelation === 'ownChildren' && age <= 5 ? 10000 : memberRelation === 'ownChildren' && (age >= 6 && age <= 10) ? 25000 : 50000}
                    step={5000}
                    value={benefit}
                    title={"Funeral Cover"}
                    disabled={disabledFuneral}
                    onChange={(changeEvent) =>
                      changeCoverAmount("funeral", changeEvent.target.value)
                    }
                  />
                </div>
              </div>
              <div className="cover-list-cost">
                <div className="cover-list-title">COST</div>
                <div className="cover-list-price">R{premium.toFixed(2)}</div>
              </div>
            </div>
          </div>

          {memberRelation === 'spouse' &&
            <div>
              <div className="cover-list">
                <div className="cover-list-item">
                  <div className="cover-list-input">
                    <input
                      disabled={disabledAccidentalCheckbox}
                      checked={accidentalDefaultChecked}
                      className="form-check-input"
                      type="checkbox"
                      value="Accidental Cover"
                      id="cover2"
                      onChange={(e) => changeSelection(e.target.checked, "accidental")}
                    />
                  </div>
                  <div className="cover-list-slider-container">
                    <div className="cover-list-title">Accidental Cover</div>
                    <div className="cover-list-slider">
                      <Slider
                        min={0}
                        max={50000}
                        step={5000}
                        title={"Accidental Cover"}
                        value={accidentalAmount}
                        onChange={(changeEvent) =>
                          changeCoverAmount("accidental", changeEvent.target.value)
                        }
                        disabled={disabledAccidental}
                      />
                    </div>
                  </div>
                  <div className="cover-list-cost">
                    <div className="cover-list-price">R{accidentalCost.toFixed(2)}</div>
                  </div>
                </div>
              </div>

              <div className="cover-list">
                <div className="cover-list-item">
                  <div className="cover-list-input">
                    <input
                      disabled={disabledIncomeCheckbox}
                      checked={incomeDefaultChecked}
                      className="form-check-input"
                      type="checkbox"
                      value="Income Cover"
                      id="cover3"
                      onChange={(e) => changeSelection(e.target.checked, "income")}
                    />
                  </div>
                  <div className="cover-list-slider-container ms-2">
                    <div className="cover-list-title">Income Booster</div>
                    <Form.Select
                      disabled={disabledIncome}
                      aria-describedby="inputGroupPrepend"
                      aria-label="IncomeBooster"
                      value={incomeAmount}
                      onChange={(e) => changeCoverAmount("income", e.target.value)}
                    >
                      <option value="">Select Title</option>
                      <option value="5000">5000</option>
                      <option value="8000">8000</option>
                      <option value="10000">10000</option>
                    </Form.Select>
                  </div>
                  {/* <div className="cover-list-slider-container">
                    <div className="cover-list-title">Income Booster</div>
                    <div className="cover-list-slider">
                      <Slider
                        min={0}
                        max={10000}
                        step={1000}
                        title={"Income Booster"}
                        value={incomeAmount}
                        onChange={(changeEvent) =>
                          changeCoverAmount("income", changeEvent.target.value)
                        }
                        disabled={disabledIncome}
                      />
                    </div>
                  </div> */}
                  <div className="cover-list-cost">
                    <div className="cover-list-title"></div>
                    <div className="cover-list-price">R{incomeCost.toFixed(2)}</div>
                  </div>
                </div>
              </div>
              <div className="cover-list-item">
                <div className="cover-list-slider-container">
                  <div className="cover-list-title-total">Total Premium Cost</div>
                </div>
                <div className="cover-list-cost">
                  <div className="cover-list-price-total">R{totalCost}</div>
                </div>
              </div>
              <div className="text-center text-sm my-2">
                <p>VALUE ADDED BENEFIT (ONLY)</p>
                <p>
                  TERMINAL ILLNESS BENEFITS 20% OF FUNERAL COVER <strong>R{illnessBenefits}</strong>
                </p>
              </div>
              {availableCost === 0 && <p className="text-center" style={{ color: "red", fontSize: "12px", fontStyle: "bold" }}>
                The Maximum Total Cover 100 000 has been selected
              </p>}
              {availableCost < 0 && <p className="text-center" style={{ color: "red", fontSize: "12px", fontStyle: "bold" }}>
                The Maximum Total Cover 100 000 has been exceed. Please Reduce Cover Amount
              </p>}
              <div className="text-center">
                <h3 className="text-primary title-md">TOTAL COVER STILL AVAILABLE</h3>
                <h2 className="text-success title-lg">R{availableCost}</h2>
              </div>
            </div>

          }

          <Footer>
            <ul className="btn-list">
              <li className="back-li">
                <BtnCompo
                  buttonAction={"/extended-family-selection"}
                  buttonIcon={"/images/icon-back.png"}
                  buttonClass={"btn-back"}
                  buttonStyle={"small"}
                  buttonIconHeight={22}
                  buttonIconWidth={27}
                />
              </li>
              <li>
                <BtnCompo
                  buttonAction={"/rather-call-me"}
                  buttonText={"RATHER CALL ME"}
                  buttonOutline={true}
                  buttonClass={"btn-rather"}
                  buttonStyle={"small"}
                />
              </li>
              <li>
                <Button
                  disabled={!name || !surname || !dateOfBirth || !age || !gender || !benefit || !premium || !relationship}
                  variant="primary"
                  size="sm"
                  onClick={(e) => { handleSubmit(e, "next"); setApproved(true); setData("next") }}
                >
                  NEXT
                </Button>
              </li>
              {parseInt(index) < 9 ? (
                <li>
                  <Button
                    disabled={!name || !surname || !dateOfBirth || !age || !gender || !benefit || !premium || !relationship}
                    variant="outline-primary "
                    className="btn-rather"
                    size="sm"
                    onClick={(e) => { handleSubmit(e, "addNew"); setApproved(true); setData("addNew") }}
                  >
                    Add Another
                  </Button>
                </li>
              ) : (
                ""
              )}
            </ul>
          </Footer>
        </Form>
      </div>

      <Modal show={show} onHide={handleClose} centered backdrop={false}>
        <Modal.Header closeButton>
          <Modal.Title>NOTE:</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {memberRelation === 'spouse' && !isCoverSelect ?
            <div>
              <p>
                1) the legal or common law husband/wife of the Policyholder or such person residing with the Principal Member, who is normally regarded by the community as the Principal Member's husband/wife. This definition shall not be limited to one (1) Spouse per Member but shall include more than one Spouse provided that any additional Spouse can be validated and is legitimate.
              </p>
              <p>
                2) any additional Spouse will be charged an extra premium. Upon Entry, which can take place at any time, a waiting period for natural death of six (6) months will be applied for such person.
              </p>
              <p>3) a primary Spouse can only be replaced after death by a new Spouse. A waiting period of six (6) months will be applied upon Entry.</p>
            </div> : <div>
              {type === 'income' && <p> A benefit that will provide three regular monthly payments following the death of the Spouse. This benefit may be selected as an additional benefit.</p>}
              {type === 'accidental' && <p> A sudden, uncertian and unexpected event which is caused solely and directly by Violent, External, Physical and Visible means and independently of any other causes resulting in the death of the Insured withing 30 days of the accidental injury. </p>}
              {type === 'funeral' && <p>An accelerated lump sum benefit of 20% of the selected funeral benefit is paid if the Spouse is diagnosed with a terminal illness and has 12 months to live. A similar waiting period as the death benefit will apply.</p>}
            </div>}
          {memberRelation === 'ownChildren' && <div>
            <p>
              Your own, unmarried, financially dependent Children, who have not yet attained the age of 21 years and shall include natural children, legally adopted children, foster children and terms of (7) below and stepchildren.
            </p>
            <p>
              2) this age per (1) above may be extended to 26 in respect of an unmarried Child who is a full-time student, provided this Child remains financially dependent on the Policyholder and if such Child is registered as a full-time student at a recognised tertiary institution.
            </p>
            <p>3) there will be no age restriction for Children who are either mentally or physically incapacitated from maintaining themselves, provided that the Children are wholly dependent on the Policyholder for support and maintenance.</p>
            <p>4) once a Child has become independent from the Policyholder for maintenance and support, then that Child no longer falls under this definition but as an Extended Family Member.  Cover for the independent child can continue provided it is applied for. Any additional independent children will be charged an additional premium.</p>
            <p>5) if the Child later resumes dependence on the Principal Member, that Child may again be covered under this Funeral policy provided that such Child meets the criteria laid down above.</p>
            <p>6) a stillborn Child is included under this definition provided that there is at least twenty-six (26) weeks of intra-uterine existence and that the foetus showed no life after complete birth.  Stillborn shall exclude the intentional termination of the life of the Child.</p>
            <p>7) any child that exceeds 21 years of age, either as an unmarried full-time student up to the age of 26 or as an incapacitated child in terms of (3) above and is entitled to a benefit in terms of this Policy shall be entitled to the benefit applicable to a Child 21 years of age.  A foster child is a child who has been placed in the insured’ custody by a court as a result of being orphaned, abandoned and/or at risk of being. abused.  Any foster child that remains in the custody of the insured’s care and must be under the age of 18 years old, or until the age of 21 years, provided this extension is legally approved.  If this extension is not approved, the child can be added as an extended family member</p>
            <p>8) Independent children above the age of 21, to the extent they are included by the insured as Extended Family Members.</p>
            <p>9) Grandchildren, to the extent they are included by the insured as Extended Family Member.</p>
          </div>}
          {(memberRelation === 'widerFamily' || memberRelation === 'formerSpouse') && <div>
            <p>
              up to eight (8) extended family members may be added to this policy. Additional Extended Family Members will be charged an extra premium.  Upon Entry, which can take place at any time, a waiting period for natural death of six (6) months will be applied for such person.  And shall include the Spouses family as defined hereunder:
            </p>
            <p>1) Great-grandparent who is your parents’ grandparent</p>
            <p>2) Great-grandparent-in-law who is the grandparent of your spouse’s parents</p>
            <p>3) Grandparent who is your parents’ parent</p>
            <p>4) Grandparent-in-law who is the parent of your spouse’s parents</p>
            <p>5) Parent who is your natural Father or Mother or your adoptive Father or Mother.</p>
            <p>6) Parent-in-law who is the parent of your spouse or who is your spouse’s natural Father or Mother or your spouse’s adoptive Father or Mother.</p>
            <p>7) Aunt who is the sister of your father or mother, or your uncle’s female spouse.</p>
            <p>8) Uncle who is the brother of your father or mother, or your aunt’s male spouse.</p>
            <p>9) Brother who is your parents’ male child.</p>
            <p>10) Sister who is your parents’ female child.</p>
            <p>11) Brother-in-law who is the male person married to the child of your parents.</p>
            <p>12) Sister-in-law who is the female person married to the child of your parents.</p>
            <p>13) First cousin who is the child of your aunt or uncle as defined above.</p>
            <p>14) Nephew who is the male child of your brother or sister.</p>
            <p>15) Niece who is the female child of your brother or sister.</p>
            <p>16) First cousin’s child who is the child of your first cousin.</p>
            <p>17) Grandchild who is your child’s child.</p>
            <p>18) Great-grandchild who is your grandchild’s child.</p>
            <p>19) Former / ex-spouse, where the bonds of marriage has been dissolved.</p>
            <p>20) Father of my child</p>
            <p>21) Mother of my child</p>
          </div>}
        </Modal.Body>
      </Modal>
    </Container >
  );
};

export default ExtendedFamilyCover;
