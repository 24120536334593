import React, { useEffect, useRef, useState } from "react";
import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import ImgCompo from "components/img-compo";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";

const initialState = {
  preview: "",
  raw: "",
};

const initialStateSecondary = {
  preview: "",
  raw: "",
};

const Beneficiary = () => {
  const [validated, setValidated] = useState(false);
  const [cellNumberError, setCellNumberError] = useState("");
  const [secondaryCellNumberError, setsecondaryCellNumberError] = useState("");
  const [spouseData, setSpouseData] = useState([]);
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [beneficiarySurname, setBeneficiarySurname] = useState("");
  const [beneficiaryDOB, setBeneficiaryDOB] = useState("");
  const [beneficiaryAge, setBeneficiaryAge] = useState("");
  const [beneficiaryRelationship, setBeneficiaryRelationship] = useState("");
  const [beneficiaryGender, setBeneficiaryGender] = useState("");
  const [beneficiaryCellNumber, setBeneficiaryCellNumber] = useState("");
  const [beneficiaryIDNo, setBeneficiaryIDNo] = useState("");
  const [IDNumberError, setIDNumberError] = useState("");
  const [beneficiaryUploadID, setBeneficiaryUploadID] = useState(initialState);
  const [beneficiarySecondaryName, setBeneficiarySecondaryName] = useState("");
  const [beneficiarySecondarySurname, setBeneficiarySecondarySurname] = useState("");
  const [beneficiarySecondaryDOB, setBeneficiarySecondaryDOB] = useState("");
  const [beneficiarySecondaryAge, setBeneficiarySecondaryAge] = useState("");
  const [beneficiarySecondaryRelationship, setBeneficiarySecondaryRelationship] = useState("");
  const [beneficiarySecondaryGender, setBeneficiarySecondaryGender] = useState("");
  const [beneficiarySecondaryCellNumber, setBeneficiarySecondaryCellNumber] = useState("");
  const [beneficiarySecondaryIDNo, setBeneficiarySecondaryIDNo] = useState("");
  const [beneficiarySecondaryUploadID, setBeneficiarySecondaryUploadID] = useState(initialStateSecondary);
  const [secIDNumberError, setSecIDNumberError] = useState("");
  const [ageError, setBeneficiaryAgeError] = useState("");
  const [ageSecondaryError, setBeneficiarySecondaryAgeError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [approved, setApproved] = useState(false);

  const inputRef = useRef(null);
  const secondaryInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    if (localStorage.getItem("extendedFamily")) {
      const extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
      if (extendedFamily.length > 0) {
        let spouseDatas = extendedFamily.filter(item => item.memberRelation === 'spouse');
        setSpouseData(spouseDatas);
      }
    }

    if (localStorage.getItem("beneficiary")) {
      const beneficiary = JSON.parse(localStorage.getItem("beneficiary"));
      setBeneficiaryName(beneficiary.beneficiaryName);
      setBeneficiarySurname(beneficiary.beneficiarySurname);
      setBeneficiaryDOB(beneficiary.beneficiaryDOB);
      setBeneficiaryAge(beneficiary.beneficiaryAge);
      setBeneficiaryRelationship(beneficiary.beneficiaryRelationship);
      setBeneficiaryGender(beneficiary.beneficiaryGender);
      setBeneficiaryCellNumber(beneficiary.beneficiaryCellNumber);
      setBeneficiaryIDNo(beneficiary.beneficiaryIDNo);
      setBeneficiaryUploadID({ preview: beneficiary.beneficiaryUploadID });
      if (beneficiary.beneficiarySecondaryName) setBeneficiarySecondaryName(beneficiary.beneficiarySecondaryName);
      if (beneficiary.beneficiarySecondarySurname) setBeneficiarySecondarySurname(beneficiary.beneficiarySecondarySurname);
      if (beneficiary.beneficiarySecondaryDOB) setBeneficiarySecondaryDOB(beneficiary.beneficiarySecondaryDOB);
      if (beneficiary.beneficiarySecondaryAge) setBeneficiarySecondaryAge(beneficiary.beneficiarySecondaryAge);
      if (beneficiary.beneficiarySecondaryRelationship) setBeneficiarySecondaryRelationship(beneficiary.beneficiarySecondaryRelationship);
      if (beneficiary.beneficiarySecondaryGender) setBeneficiarySecondaryGender(beneficiary.beneficiarySecondaryGender);
      if (beneficiary.beneficiarySecondaryCellNumber) setBeneficiarySecondaryCellNumber(beneficiary.beneficiarySecondaryCellNumber);
      if (beneficiary.beneficiarySecondaryIDNo) setBeneficiarySecondaryIDNo(beneficiary.beneficiarySecondaryIDNo);
      if (beneficiary.beneficiarySecondaryUploadID) setBeneficiarySecondaryUploadID({ preview: beneficiary.beneficiarySecondaryUploadID });
    }
  }, []);

  const spouseChecked = (e) => {
    if (e.target.checked) {
      setBeneficiaryName(spouseData[0].name);
      setBeneficiarySurname(spouseData[0].surname);
      setBeneficiaryDOB(spouseData[0].dateOfBirth);
      setBeneficiaryAge(spouseData[0].age);
      setBeneficiaryRelationship(spouseData[0].relationship);
      setBeneficiaryGender(spouseData[0].gender);
    } else {
      setBeneficiaryName("");
      setBeneficiarySurname("");
      setBeneficiaryDOB("");
      setBeneficiaryAge("");
      setBeneficiaryRelationship("");
      setBeneficiaryGender("");
    }
  }

  const checkIDNumber = (number, type) => {
    if (number.length < 13) {
      if (type === 'IDNumber') setIDNumberError("Please give a valid ID Number");
      if (type === 'secIDNumber') setSecIDNumberError("Please give a valid ID Number");
      return false;
    } else {
      setIDNumberError("");
      setSecIDNumberError("");
      return true;
    }
  }

  const handleChange = (e, value) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (value === 'cellNumber') setBeneficiaryCellNumber(e.target.value);
      if (value === 'secondaryNo') setBeneficiarySecondaryCellNumber(e.target.value);
    }
  };

  const handleImgChange = (e, type) => {
    if (e.target.files.length) {
      if (type === "primary") {
        setBeneficiaryUploadID({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
      }
      if (type === "secondary") {
        setBeneficiarySecondaryUploadID({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
      }
    };
  }

  const handleClear = (e, type) => {
    e.preventDefault();
    if (type === "primary") {
      inputRef.current.value = null;
      setBeneficiaryUploadID(initialState);
    }
    if (type === "secondary") {
      secondaryInputRef.current.value = null;
      setBeneficiarySecondaryUploadID(initialStateSecondary);
    }
  };

  const checkCellNumber = (number, type) => {
    if (number.charAt(0) === "0") {
      if (number.length > 10) {
        if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
        if (type === 'secondaryNo') setsecondaryCellNumberError("Please give a valid cell number");
        return false;
      } else if (number.length < 10) {
        if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
        if (type === 'secondaryNo') setsecondaryCellNumberError("Please give a valid cell number");
        return false;
      } else {
        if (type === 'cellNumber') setCellNumberError("");
        if (type === 'secondaryNo') setsecondaryCellNumberError("");
        return true;
      }
    } else if (number.charAt(0) === "2") {
      if (number.length > 11) {
        if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
        if (type === 'secondaryNo') setsecondaryCellNumberError("Please give a valid cell number");
        return false;
      } else if (number.length < 11) {
        if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
        if (type === 'secondaryNo') setsecondaryCellNumberError("Please give a valid cell number");
        return false;
      } else {
        if (type === 'cellNumber') setCellNumberError("");
        if (type === 'secondaryNo') setsecondaryCellNumberError("");
        return true;
      }
    } else {
      if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
      if (type === 'secondaryCellNumber' && number.length !== 0) setsecondaryCellNumberError("Please give a valid cell number");
    }
  };

  const changeDOB = (value, type) => {
    const actualValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    if (actualValue.length === 8) {
      if (type === 'primary') setBeneficiaryDOB(value);
      else setBeneficiarySecondaryDOB(value);
      getAge(value, type);
    }
  };

  const generateDOB = (value, type) => {
    let dob = '';
    const strlenth = (value.length - 1);
    for (var i = 0; i < strlenth; i++) {
      if (i < (strlenth - 6)) dob += value[i];
    }
    if (dob) {
      let year;
      let month = String(dob).substring(2, 4);
      let day = String(dob).substring(4, 6);
      const firstTwoChars = String(dob).substring(0, 2);
      if (firstTwoChars >= 45) year = "19" + firstTwoChars;
      else year = "20" + firstTwoChars;
      let finalDOB = year + "-" + month + "-" + day;
      if (day) {
        if (type === 'beneficiaryIDNo') {
          setBeneficiaryDOB(finalDOB);
          getAge(finalDOB, 'primary');
        } else {
          setBeneficiarySecondaryDOB(finalDOB);
          getAge(finalDOB, 'secondary');
        }
      }
    }
  }

  const getAge = (dateString, type) => {
    var now = new Date();
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var dob = new Date(dateString);
    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearAge = "";

    yearAge = yearNow - yearDob;
    var monthAge;
    if (monthNow >= monthDob) monthAge = monthNow - monthDob;
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }

    var dateAge;
    if (dateNow >= dateDob) dateAge = dateNow - dateDob;
    else {
      monthAge--;
      dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    if (dateAge === 0) dateAge = 1;
    if (monthAge === 0) monthAge = 1;

    age = { years: yearAge, months: monthAge, days: dateAge };
    if (age.years > 0 && age.months > 0 && age.days > 0) ageString = age.years;
    else if (age.years === 0 && age.months > 0 && age.days > 0)
      ageString = "0." + age.months;


    if (type === 'primary') {
      if (ageString <= 18) setBeneficiaryAgeError(`Beneficiary age must be above 18.`);
      else setBeneficiaryAgeError();
      setBeneficiaryAge(ageString);
    } else {
      if (ageString <= 18) setBeneficiarySecondaryAgeError(`Beneficiary age must be above 18.`);
      else setBeneficiarySecondaryAgeError("");
      setBeneficiarySecondaryAge(ageString);
    };
  }

  useEffect(() => {
    if (approved) {
      setIsLoading(true);
      let representativeData; let profile; let selfCoverDatas; let extendedFamily; let totalCost; let employeeDetail; let MainId; let address; let beneficiary; let indexPage;
      let deviceInfo;
      if (localStorage.getItem("selfCoverData")) selfCoverDatas = JSON.parse(localStorage.getItem("selfCoverData"));
      if (localStorage.getItem("representativeData")) representativeData = JSON.parse(localStorage.getItem("representativeData"));
      if (localStorage.getItem("profile")) profile = JSON.parse(localStorage.getItem("profile"));
      if (localStorage.getItem("extendedFamily")) extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
      if (localStorage.getItem("indexPage")) indexPage = JSON.parse(localStorage.getItem("indexPage"));
      if (localStorage.getItem("totalCost")) totalCost = JSON.parse(localStorage.getItem("totalCost"));
      if (localStorage.getItem("employeeDetails")) employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
      if (localStorage.getItem("MainId")) MainId = JSON.parse(localStorage.getItem("MainId"));
      if (localStorage.getItem("profileAddress")) address = JSON.parse(localStorage.getItem("profileAddress"));
      if (localStorage.getItem("beneficiary")) beneficiary = JSON.parse(localStorage.getItem("beneficiary"));
      if (localStorage.getItem("deviceInfo")) deviceInfo = JSON.parse(localStorage.getItem("deviceInfo"));

      let payload = {
        page: 'beneficiary',
        sourceData: "funeralApp",
        representativeData,
        profile,
        selfCoverDatas,
        extendedFamily,
        indexPage,
        totalCost,
        address,
        employeeDetail,
        beneficiary,
        MainId,
        deviceInfo
      }

      var config = {
        method: "post",
        url: "https://apiv2.msgl.ink/",
        headers: {
          "content-type": "application/json",
        },
        data: {
          ...payload,
        },
      };
      axios(config)
        .then(function (response) {
          setIsLoading(false);
          navigate("/payment-method");
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }

  }, [approved, navigate])

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (beneficiaryName &&
      beneficiarySurname &&
      beneficiaryDOB &&
      beneficiaryRelationship &&
      beneficiaryGender &&
      beneficiaryCellNumber &&
      beneficiaryIDNo) {
      let data;
      let secondaryData;

      if (beneficiaryUploadID.preview) {
        const formData = new FormData();
        formData.append("image", beneficiaryUploadID.raw);
        const path = document.getElementById("uploadImgPrimary");
        data = path.src;
      }

      if (beneficiarySecondaryUploadID.preview) {
        const formData = new FormData();
        formData.append("image", beneficiarySecondaryUploadID.raw);
        const path = document.getElementById("uploadImgSecondary");
        secondaryData = path.src;
      }

      const beneficiary = {
        beneficiaryName: beneficiaryName,
        beneficiarySurname: beneficiarySurname,
        beneficiaryAge: beneficiaryAge,
        beneficiaryDOB: beneficiaryDOB,
        beneficiaryRelationship: beneficiaryRelationship,
        beneficiaryGender: beneficiaryGender,
        beneficiaryCellNumber: beneficiaryCellNumber,
        beneficiaryIDNo: beneficiaryIDNo,
        beneficiaryUploadID: data,
        beneficiarySecondaryName: beneficiarySecondaryName,
        beneficiarySecondarySurname: beneficiarySecondarySurname,
        beneficiarySecondaryAge: beneficiarySecondaryAge,
        beneficiarySecondaryDOB: beneficiarySecondaryDOB,
        beneficiarySecondaryRelationship: beneficiarySecondaryRelationship,
        beneficiarySecondaryGender: beneficiarySecondaryGender,
        beneficiarySecondaryCellNumber: beneficiarySecondaryCellNumber,
        beneficiarySecondaryIDNo: beneficiarySecondaryIDNo,
        beneficiarySecondaryUploadID: secondaryData
      };
      if (!IDNumberError && !cellNumberError && !secondaryCellNumberError) {
        localStorage.setItem("beneficiary", JSON.stringify(beneficiary));
        setValidated(false);
      } else event.preventDefault();
    } else {
      event.preventDefault();
      setValidated(true);
    }
  };

  return (
    <Container className="h-100">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Data, please wait...</div>
      </div>}
      <div className="main-content">
        <h1 className="page-title mb-5">
          ADD YOUR BENEFICIARY DETAILS
        </h1>

        <Form noValidate validated={validated}>
          <div className="page-header">PRIMARY BENEFICIARY</div>
          <div className="m-1">
            <Row className="mb-3 g-3">
              {spouseData.length > 0 && <Form.Group as={Col} md="12">
                <Form.Check
                  type="checkbox"
                  id="check1"
                  label="Add Spouse as a Primary Beneficiary"
                  onChange={(e) => spouseChecked(e)}
                  className="mb-0"
                />
              </Form.Group>}
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiaryName">First Name *</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-user.png"
                      alt="FirstName"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="First Name"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiaryName}
                    onChange={(e) => setBeneficiaryName(e.target.value)}
                  />
                  <Form.Control.Feedback type="valid">
                    First Name is valid.
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a First Name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiarySurname">Surname *</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-user.png"
                      alt="beneficiarySurname"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Surname"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiarySurname}
                    onChange={(e) => setBeneficiarySurname(e.target.value)}
                  />
                  <Form.Control.Feedback type="valid">
                    Surname is valid.
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a Surname.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiaryIDNo">ID Number *</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-user.png"
                      alt="IDNumber"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="ID Number"
                    aria-describedby="inputGroupPrepend"
                    isInvalid={IDNumberError}
                    maxLength={13}
                    value={beneficiaryIDNo}
                    onChange={(e) => { setBeneficiaryIDNo(e.target.value); generateDOB(e.target.value, "beneficiaryIDNo"); checkIDNumber(e.target.value, 'IDNumber'); }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {IDNumberError
                      ? IDNumberError
                      : "Please Enter a ID Number."}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} xs="6">
                <Form.Label htmlFor="mainDOB">Date Of Birth *</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-birthdate.png"
                      alt="DateOfBirth"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="date"
                    placeholder="Date Of Birth"
                    aria-describedby="inputGroupPrepend"
                    onChange={(e) => changeDOB(e.target.value, "primary")}
                    value={beneficiaryDOB}
                  />
                  <Form.Control.Feedback type="valid">
                    Date Of Birth is valid.
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a Date Of Birth.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} xs="6">
                <Form.Label htmlFor="mainAge">Age</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-age.png"
                      alt="Age"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="Age"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiaryAge}
                    disabled
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiaryRelationship">Relationship *</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-relation.png"
                      alt="beneficiaryRelationship"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Relationship"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiaryRelationship}
                    onChange={(e) => setBeneficiaryRelationship(e.target.value)}
                  />
                  <Form.Control.Feedback type="valid">
                    Relationship is valid.
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a Relationship.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="gender">Gender *</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-gender.png"
                      alt="Gender"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Select
                    required
                    value={beneficiaryGender}
                    onChange={(e) => setBeneficiaryGender(e.target.value)}
                    aria-describedby="inputGroupPrepend"
                    aria-label="Gender"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Select>

                  <Form.Control.Feedback type="valid">
                    Gender is valid.
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please choose a Gender.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiaryCellNumber">
                  Cell Phone Number *
                </Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-phone.png"
                      alt="beneficiaryCellNumber"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Cell Phone Number"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiaryCellNumber}
                    isInvalid={cellNumberError}
                    maxLength="11"
                    onChange={(e) => { handleChange(e, 'cellNumber'); checkCellNumber(e.target.value, 'cellNumber') }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {cellNumberError
                      ? cellNumberError
                      : "Please choose a Cell Phone Number."}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              {ageError && (
                <div className="text-center text-sm" style={{ color: "red" }}>
                  <p>{ageError}</p>
                </div>
              )}
              <div className="page-header mb-4">UPLOAD ID</div>
              <div className="m-auto px-3 d-flex flex-column align-items-center justify-content-center">
                <div className="mb-4">
                  <label htmlFor="upload-button" className="img-uploader-label                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                    {beneficiaryUploadID.preview ? (
                      <ImgCompo
                        src={beneficiaryUploadID.preview}
                        alt="Uploaded ID"
                        className="img-fluid"
                        id="uploadImgPrimary"
                      />
                    ) : (
                      <ImgCompo
                        src={"/images/icon-upload-id.png"}
                        alt="Upload ID"
                        height={128}
                        width={128}
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    id="upload-button"
                    ref={inputRef}
                    hidden
                    accept="image/*"
                    onChange={(e) => handleImgChange(e, "primary")}
                  />
                </div>
                {beneficiaryUploadID.preview ? (
                  <ul className="btn-list">
                    <li>
                      <Button
                        onClick={(e) => handleClear(e, "primary")}
                        variant="outline-secondary"
                        size="sm"
                        className="fs-6"
                      >
                        Clear
                      </Button>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
                {/* {!beneficiaryUploadID.preview ? <div className="text-center text-sm" style={{ color: "red" }}>
                  <p>Please Upload Primary Beneficiary ID</p>
                </div> : ''} */}
              </div>
            </Row>
          </div>

          <div className="page-header">SECONDARY BENEFICIARY (OPTIONAL)</div>
          <div className="m-1">
            <Row className="mb-3 g-3">
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiaryName">First Name</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-user.png"
                      alt="FirstName"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiarySecondaryName}
                    onChange={(e) => setBeneficiarySecondaryName(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiarySurname">Surname</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-user.png"
                      alt="beneficiarySurname"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Surname"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiarySecondarySurname}
                    onChange={(e) => setBeneficiarySecondarySurname(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiaryIDNo">ID Number</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-user.png"
                      alt="IDNumber"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="ID Number"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiarySecondaryIDNo}
                    isInvalid={secIDNumberError}
                    maxLength={13}
                    onChange={(e) => { setBeneficiarySecondaryIDNo(e.target.value); generateDOB(e.target.value, "beneficiarySecondaryIDNo"); checkIDNumber(e.target.value, 'secIDNumber') }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {secIDNumberError
                      ? secIDNumberError
                      : "Please Enter a ID Number."}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} xs="6">
                <Form.Label htmlFor="mainDOB">Date Of Birth</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-birthdate.png"
                      alt="DateOfBirth"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="date"
                    placeholder="Date Of Birth"
                    aria-describedby="inputGroupPrepend"
                    onChange={(e) => changeDOB(e.target.value, "secondary")}
                    value={beneficiarySecondaryDOB}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} xs="6">
                <Form.Label htmlFor="mainAge">Age</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-age.png"
                      alt="Age"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder="Age"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiarySecondaryAge}
                    disabled
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiaryRelationship">Relationship</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-relation.png"
                      alt="beneficiaryRelationship"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Relationship"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiarySecondaryRelationship}
                    onChange={(e) => setBeneficiarySecondaryRelationship(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="gender">Gender</Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-gender.png"
                      alt="Gender"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Select
                    value={beneficiarySecondaryGender}
                    onChange={(e) => setBeneficiarySecondaryGender(e.target.value)}
                    aria-describedby="inputGroupPrepend"
                    aria-label="Gender"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Select>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="beneficiaryCellNumber">
                  Cell Phone Number
                </Form.Label>
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">
                    <ImgCompo
                      src="/images/icon-phone.png"
                      alt="beneficiaryCellNumber"
                      width={16}
                      height={16}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Cell Phone Number"
                    aria-describedby="inputGroupPrepend"
                    value={beneficiarySecondaryCellNumber}
                    isInvalid={secondaryCellNumberError}
                    maxLength="11"
                    onChange={(e) => { handleChange(e, "secondaryNo"); checkCellNumber(e.target.value, 'secondaryNo') }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {secondaryCellNumberError && secondaryCellNumberError}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {ageSecondaryError && (
                <div className="text-center text-sm" style={{ color: "red" }}>
                  <p>{ageSecondaryError}</p>
                </div>
              )}
              <div className="page-header mb-4">UPLOAD ID</div>
              <div className="m-auto px-3 d-flex flex-column align-items-center justify-content-center">
                <div className="mb-4">
                  <label htmlFor="upload-button-1" className="img-uploader-label                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ">
                    {beneficiarySecondaryUploadID.preview ? (<ImgCompo
                      src={beneficiarySecondaryUploadID.preview}
                      alt="Uploaded ID"
                      className="img-fluid"
                      id="uploadImgSecondary"
                    />) : (
                      <ImgCompo
                        src={"/images/icon-upload-id.png"}
                        alt="Upload ID"
                        height={128}
                        width={128}
                      />
                    )}
                  </label>
                  <input
                    type="file"
                    id="upload-button-1"
                    hidden
                    ref={secondaryInputRef} accept="image/*" onChange={(e) => handleImgChange(e, "secondary")}
                  />
                </div>
                {beneficiarySecondaryUploadID.preview ? (
                  <ul className="btn-list">
                    <li>
                      <Button
                        onClick={(e) => handleClear(e, "secondary")}
                        variant="outline-secondary"
                        size="sm"
                        className="fs-6"
                      >
                        Clear
                      </Button>
                    </li>
                  </ul>
                ) : (
                  beneficiarySecondaryCellNumber && <div className="text-center text-sm" style={{ color: "red" }}>
                    <p>Please Upload Secondary Beneficiary ID</p>
                  </div>
                )}
              </div>
            </Row>
          </div>

          <Footer>
            <ul className="btn-list">
              <li className="back-li">
                <BtnCompo
                  buttonAction={"/employee-details"}
                  buttonIcon={"/images/icon-back.png"}
                  buttonClass={"btn-back"}
                  buttonIconHeight={22}
                  buttonIconWidth={27}
                />
              </li>
              <li>
                <BtnCompo
                  buttonAction={"/rather-call-me"}
                  buttonText={"RATHER CALL ME"}
                  buttonOutline={true}
                  buttonClass={"btn-rather"}
                />
              </li>
              <li>
                <Button disabled={(!beneficiaryName ||
                  !beneficiarySurname ||
                  !beneficiaryDOB ||
                  !beneficiaryRelationship ||
                  !beneficiaryGender ||
                  !beneficiaryCellNumber || cellNumberError || ageError)} variant="primary" onClick={(e) => { handleSubmit(e); setApproved(true) }}>
                  NEXT
                </Button>
              </li>
            </ul>
          </Footer>
        </Form>
      </div>
    </Container>
  );
};

export default Beneficiary;
