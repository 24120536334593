import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, InputGroup } from "react-bootstrap";
import ImgCompo from "components/img-compo";
import Footer from "components/footer";
import OtpInput from 'react-otp-input';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import XMLParser from 'react-xml-parser';

const AutoSaveForm = () => {
    const [IDNumberError, setIDNumberError] = useState("");
    const [validated, setValidated] = useState(false);
    const [IDNumber, setIDNumber] = useState("");
    const [cellNumber, setCellNumber] = useState("");
    const [cellNumberError, setCellNumberError] = useState("");
    const [submitData, setSubmitData] = useState(false);
    const [receviedOTP, setReceviedOTP] = useState();
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState();
    const [timerStart, setTimerStart] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);
    const [disabledResend, setDisabledResend] = useState(false);
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setCellNumber(e.target.value);
        }
    };

    const checkCellNumber = (number) => {
        if (number.charAt(0) === "0") {
            if (number.length > 10) {
                setCellNumberError("Please give a valid cell number");

                return false;
            } else if (number.length < 10) {
                setCellNumberError("Please give a valid cell number");
                return false;
            } else {
                setCellNumberError("");
                return true;
            }
        } else if (number.charAt(0) === "2") {
            if (number.length > 11) {
                setCellNumberError("Please give a valid cell number");
                return false;
            } else if (number.length < 11) {
                setCellNumberError("Please give a valid cell number");
                return false;
            } else {
                setCellNumberError("");
                return true;
            }
        } else {
            setCellNumberError("Please give a valid cell number");
        }
    };

    const checkIDNumber = (number) => {
        if (number.length < 13) {
            setIDNumberError("Please give a valid ID Number");
            return false;
        } else {
            setIDNumberError("");
            return true;
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (IDNumber && cellNumber && !cellNumberError) {
            setIsLoading(true);
            GetOTP();
        }
        setValidated(true);
    }

    const GetOTP = () => {
        const xml = `<?xml version="1.0" encoding="utf-8"?>
            <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                <soap:Body>
                    <ShieldOTPGet xmlns="http://tempuri.org/">
                        <mobile>${cellNumber}</mobile>
                        <id>${IDNumber}</id>
                    </ShieldOTPGet>
                </soap:Body>
            </soap:Envelope>`

        var config = {
            method: "post",
            url: "https://shieldapp.msgportal.online/msgportal.asmx?op=ShieldOTPGet",
            data: xml,
            headers: {
                'Content-Type': 'text/xml'
            }
        };

        axios(config)
            .then(function (response) {
                var xml = new XMLParser().parseFromString(response.data);
                const data = xml.children[0].children[0].children[0].value;
                setIsLoading(false);
                if (data !== "nodata") {
                    setError(false);
                    setSubmitData(true);
                    setReceviedOTP(data);
                } else setError(true);
            })
            .catch(function (error) {
                setIsLoading(false);
            });
    }

    const resendOTP = () => {
        GetOTP();
    }

    useEffect(() => {
        localStorage.clear();
        if (timerStart) {
            if (timeLeft === 0) {
                setDisabledResend(false);
                setTimeLeft(null)
            }

            if (!timeLeft) return;

            const intervalId = setInterval(() => {
                setDisabledResend(true);
                setTimeLeft(timeLeft - 1);
            }, 1000);

            return () => clearInterval(intervalId);
        }

    }, [timeLeft, timerStart])

    const validateOTP = () => {
        setError(false);
        if (otp === receviedOTP) {
            setIsLoading(true);
            const xml = `<?xml version="1.0" encoding="utf-8"?>
            <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                <soap:Body>
                    <ShieldPLGet xmlns="http://tempuri.org/">
                        <token>lkhbnoyti76tbri7tfjyfcubyt75rtbfity</token>
                        <id>${IDNumber}</id>
                    </ShieldPLGet>
                </soap:Body>
            </soap:Envelope>`

            var config = {
                method: 'post',
                url: 'https://shieldapp.msgportal.online/msgportal.asmx?op=ShieldPLGet',
                data: xml,
                headers: {
                    'Content-Type': 'text/xml'
                }
            };

            axios(config)
                .then(response => {
                    setIsLoading(false);
                    var xml = new XMLParser().parseFromString(response.data);
                    if (xml.getElementsByTagName('ShieldPLGetResult').length > 0) {
                        let data = JSON.parse(xml.getElementsByTagName('ShieldPLGetResult')[0].value);
                        if (data.length > 0) {
                            data = { ...data[0] }
                            console.log(data)
                            if (data.representativeData) localStorage.setItem("representativeData", JSON.stringify(data.representativeData));
                            if (data.profile) localStorage.setItem("profile", JSON.stringify(data.profile));
                            if (data.selfCoverDatas) localStorage.setItem('selfCoverData', JSON.stringify(data.selfCoverDatas));
                            if (data.extendedFamily) localStorage.setItem("extendedFamily", JSON.stringify(data.extendedFamily));
                            if (data.indexPage) localStorage.setItem("indexPage", JSON.stringify(data.indexPage));
                            if (data.totalCost) localStorage.setItem('totalCost', JSON.stringify(data.totalCost));
                            if (data.employeeDetail) localStorage.setItem("employeeDetails", JSON.stringify(data.employeeDetail));
                            if (data.MainId) localStorage.setItem("MainId", JSON.stringify(data.MainId));
                            if (data.address) localStorage.setItem("profileAddress", JSON.stringify(data.address));
                            if (data.beneficiary) localStorage.setItem("beneficiary", JSON.stringify(data.beneficiary));
                            if (data.bankingDetailsDO) localStorage.setItem("bankingDetailsDO", JSON.stringify(data.bankingDetailsDO));
                            if (data.checkInfoData) localStorage.setItem('checkInfoData', JSON.stringify(data.checkInfoData));
                            if (data.addInfo) localStorage.setItem('addInfo', JSON.stringify(data.addInfo));
                            if (data.page) navigate(`/${data.page}`);
                            else { alert("You have completed your application"); navigate('/'); }
                        }
                    } else { alert("No Data Found"); navigate('/'); }
                })
        } else {
            setError(true);
        }
    }

    return (
        <Container className="h-100">
            {isLoading && <div className="loaderOverlay">
                <Spinner animation="border" role="status" variant="light" />

                <div>Submitting Application, please wait...</div>
            </div>}
            <div className="main-content">
                <h1 className="page-title mb-5">
                    ADD YOUR DETAILS
                </h1>

                {!submitData ? <Form noValidate validated={validated}>
                    <Form.Group as={Col} xs="12">
                        <Form.Label htmlFor="IDNumber">ID Number *</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                                <ImgCompo
                                    src="/images/icon-user.png"
                                    alt="Surname"
                                    width={16}
                                    height={16}
                                />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder="ID Number"
                                aria-describedby="inputGroupPrepend"
                                isInvalid={IDNumberError}
                                maxLength={13}
                                value={IDNumber}
                                onChange={(e) => { setIDNumber(e.target.value); checkIDNumber(e.target.value) }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {IDNumberError
                                    ? IDNumberError
                                    : "Please Enter a ID Number."}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs="12">
                        <Form.Label htmlFor="cellNumber">Cell Phone Number *</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">
                                <ImgCompo
                                    src="/images/icon-phone.png"
                                    alt="CellNumber"
                                    width={16}
                                    height={16}
                                />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Cell Phone Number"
                                aria-describedby="inputGroupPrepend"
                                value={cellNumber}
                                isInvalid={cellNumberError}
                                maxLength={11}
                                pattern="[0-9]*"
                                onChange={(e) => { handleChange(e); checkCellNumber(e.target.value) }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {cellNumberError
                                    ? cellNumberError
                                    : "Please Enter a Cell Phone Number."}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    {error && <div style={{ color: "red", marginTop: "20px" }}>Please enter a valid IDNumber and Mobile Number</div>}
                    <Footer>
                        <ul className="btn-list">
                            <li> <Button className="text-center w-auto m-auto" variant="primary" onClick={(e) => { handleSubmit(e); }}>
                                Submit
                            </Button>
                            </li>
                        </ul>
                    </Footer>
                </Form> :
                    <>

                        <div className="d-flex flex-column align-items-center justify-content-center h-100 otp-input">
                            <h6 style={{ color: '#0b58a6' }}>Please enter OTP *</h6>
                            <OtpInput
                                className="form-control"
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderInput={(props) => <input {...props} />}
                            />
                            <Button disabled={disabledResend} className="text-center mt-3 p-2 fs-6" variant="primary" onClick={(e) => { resendOTP(); setTimerStart(true); setTimeLeft(30) }}>
                                Resend OTP
                            </Button>
                            {error && <div style={{ color: "red", marginTop: "20px" }}>Invalid OTP has been Entered</div>}
                            <Footer>
                                <ul className="btn-list">
                                    <li> <Button className="text-center w-auto m-auto" variant="primary" onClick={(e) => { validateOTP() }}>
                                        Submit
                                    </Button>
                                    </li>
                                </ul>
                            </Footer>
                        </div>
                    </>}
            </div>
        </Container>
    )
}

export default AutoSaveForm;