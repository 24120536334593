import ImgCompo from "components/img-compo";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BtnCompo from "components/btn-compo";

const Thankyou = () => {
    return (
        <Container className="h-100">
            <div className="main-content text-center">
                <h1 className="page-title m-lg-b">Thank you</h1>
                <div className="content-block">
                    <div className="content-text mb-2">
                        <p>Thank you! An SMS signing link will be sent to your mobile number shortly</p>
                    </div>
                    <BtnCompo
                        buttonAction={"/"}
                        buttonText={"Home"}
                        buttonClass={"btn-rather"}
                    />
                    <div className="content-logo">
                        <Row className="align-items-center g-5 mb-5 justify-content-between">
                            <Col>
                                <ImgCompo
                                    src="/images/logo.png"
                                    alt="Shield Life Funeral"
                                    className="mt-auto img-fluid"
                                />
                            </Col>
                            <Col>
                                <ImgCompo
                                    src="/images/MAXI_Forces.jpg"
                                    alt="Maxi Forces Financial Services"
                                    className="img-fluid"
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Thankyou;
