import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import React, { useState, useEffect } from "react";
import { Button, Form, Container, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";

const PaymentMethod = () => {
  const [validated, setValidated] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Payroll");
  const [debitOrder, setDebitOrder] = useState(false);
  const [bankName, setBankName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [debitOrderDate, setDebitOrderDate] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState();
  const [dateArray, setDateArray] = useState([]);
  const [alterMethod, setAlterMethod] = useState("yes");
  const [isLoading, setIsLoading] = useState(false);
  const [approved, setApproved] = useState("");

  const navigate = useNavigate();

  const banksArray = [
    "ABSA",
    "African Bank",
    "Bank of Athens",
    "Bidvest",
    "Capitec",
    "Discovery Bank",
    "FNB",
    "Finbond Bank",
    "Grinrod",
    "Investec Pvt Bank",
    "Mercantile",
    "Nedbank",
    "Old Mutual Bank",
    "Peoples Bank",
    "Post Bank",
    "RMB Private Bank",
    "Sasfin",
    "Standard Bank",
    "Tyme",
    "Ubank",
    "UniBank",
    "Wizzit Bank"
  ];

  useEffect(() => {
    let arr = [];
    for (let i = 1; i <= Number(31); i++) {
      arr.push(i)
    }
    setDateArray(arr);

    if (localStorage.getItem("employeeDetails")) {
      let employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
      setEmployeeDetails(employeeDetail);
    }

    if (localStorage.getItem("bankingDetailsDO")) {
      let bankingDetailsDO = JSON.parse(localStorage.getItem("bankingDetailsDO"));
      setPaymentMethod(bankingDetailsDO.paymentMethod);
      setBankName(bankingDetailsDO.Bank);
      setAccountType(bankingDetailsDO.AccountType);
      setAccountNumber(bankingDetailsDO.AccountNumber);
      setDebitOrderDate(bankingDetailsDO.debitOrderDate);
      setAlterMethod(bankingDetailsDO.alternativeMethod)
    }
  }, []);

  useEffect(() => {
    if (paymentMethod === "Payroll") {
      setDebitOrder(false);
    }
    if (paymentMethod === "DebitOrder") {
      setDebitOrder(true);
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (approved) {
      setIsLoading(true);
      let representativeData; let profile; let selfCoverDatas; let extendedFamily; let totalCost; let employeeDetail; let address; let MainId; let beneficiary; let bankingDetailsDO; let indexPage;
      let deviceInfo;
      if (localStorage.getItem("selfCoverData")) selfCoverDatas = JSON.parse(localStorage.getItem("selfCoverData"));
      if (localStorage.getItem("representativeData")) representativeData = JSON.parse(localStorage.getItem("representativeData"));
      if (localStorage.getItem("profile")) profile = JSON.parse(localStorage.getItem("profile"));
      if (localStorage.getItem("extendedFamily")) extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
      if (localStorage.getItem("indexPage")) indexPage = JSON.parse(localStorage.getItem("indexPage"));
      if (localStorage.getItem("totalCost")) totalCost = JSON.parse(localStorage.getItem("totalCost"));
      if (localStorage.getItem("employeeDetails")) employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
      if (localStorage.getItem("MainId")) MainId = JSON.parse(localStorage.getItem("MainId"));
      if (localStorage.getItem("profileAddress")) address = JSON.parse(localStorage.getItem("profileAddress"));
      if (localStorage.getItem("beneficiary")) beneficiary = JSON.parse(localStorage.getItem("beneficiary"));
      if (localStorage.getItem("bankingDetailsDO")) bankingDetailsDO = JSON.parse(localStorage.getItem("bankingDetailsDO"));
      if (localStorage.getItem("deviceInfo")) deviceInfo = JSON.parse(localStorage.getItem("deviceInfo"));

      let payload = {
        page: 'payment-method',
        sourceData: "funeralApp",
        representativeData,
        profile,
        selfCoverDatas,
        extendedFamily,
        indexPage,
        totalCost,
        address,
        employeeDetail,
        beneficiary,
        bankingDetailsDO,
        MainId,
        deviceInfo
      }

      var config = {
        method: "post",
        url: "https://apiv2.msgl.ink/",
        headers: {
          "content-type": "application/json",
        },
        data: {
          ...payload,
        },
      };
      axios(config)
        .then(function (response) {
          setIsLoading(false);
          navigate("/check-info");
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }

  }, [approved, navigate])

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (paymentMethod) {
      setValidated(true);
      var branch;
      if (paymentMethod === 'DebitOrder' || alterMethod === 'yes') {
        if (bankName && accountType && accountNumber && debitOrderDate) {
          if (bankName === "ABSA") branch = "632005";
          if (bankName === "African Bank") branch = "430000";
          if (bankName === "Bank of Athens") branch = "410105";
          if (bankName === "Bidvest") branch = "462005";
          if (bankName === "Capitec") branch = "470010";
          if (bankName === "Discovery Bank") branch = "679000";
          if (bankName === "FNB") branch = "250655";
          if (bankName === "Finbond Bank") branch = "589000";
          if (bankName === "Grinrod") branch = "223626";
          if (bankName === "Investec Pvt Bank") branch = "580105";
          if (bankName === "Mercantile") branch = "450105";
          if (bankName === "Nedbank") branch = "198765";
          if (bankName === "Old Mutual Bank") branch = "176905";
          if (bankName === "Peoples Bank ") branch = "144226";
          if (bankName === "Post Bank") branch = "460005";
          if (bankName === "RMB Private Bank") branch = "261251";
          if (bankName === "Sasfin") branch = "683000";
          if (bankName === "Standard Bank") branch = "051001";
          if (bankName === "Tyme") branch = "678910";
          if (bankName === "Ubank") branch = "431010";
          if (bankName === "UniBank") branch = "790005";
          if (bankName === "Wizzit Bank") branch = "410700";

          let bankingDetailsDO = {
            paymentMethod: paymentMethod,
            Bank: bankName,
            AccountType: accountType,
            AccountNumber: accountNumber,
            BranchCode: branch,
            debitOrderDate: debitOrderDate,
            alternativeMethod: alterMethod
          };
          if (paymentMethod === 'DebitOrder') delete bankingDetailsDO.alternativeMethod;
          localStorage.setItem("bankingDetailsDO", JSON.stringify(bankingDetailsDO));
        }
      } else if (alterMethod === 'no') {
        setValidated(false);
        let bankingDetailsDO = {
          paymentMethod: paymentMethod,
          alternativeMethod: alterMethod
        };
        localStorage.setItem("bankingDetailsDO", JSON.stringify(bankingDetailsDO));
      }
    }
  };

  return (
    <Container className="h-100">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Data, please wait...</div>
      </div>}
      <div className="main-content">
        <h1 className="page-title mb-5">PAYMENT METHOD</h1>
        <div className="content-block">
          <Form noValidate validated={validated}>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="paymentMethod">Payment Method</Form.Label>
              <InputGroup hasValidation>
                <Form.Select
                  required
                  aria-describedby="inputGroupPrepend"
                  aria-label="paymentMethod"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option disabled>Select Payment Method</option>
                  <option value="Payroll">Payroll</option>
                  <option value="DebitOrder">Debit Order</option>
                </Form.Select>
                <Form.Control.Feedback type="valid">
                  Payment Method is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Payment Method.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            {!debitOrder && <Form.Group className="mb-2" controlId="accountType">
              <Form.Label className="Label">Alterations to method of payment in case of unavailability of Payroll deduction</Form.Label>
              <Form.Select
                disabled
                value={alterMethod}
                onChange={(e) => setAlterMethod(e.target.value)}
              >
                <option value="">Choose method of payment</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Select>
              <Form.Control.Feedback type="valid">
                Account Type is valid.
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please Enter a Account Type.
              </Form.Control.Feedback>
            </Form.Group>
            }

            {(alterMethod === "yes" || debitOrder) && <div>
              <h6 className="title-sm text-center">Banking Details</h6>
              <Form.Group className="mb-2" controlId="bankName">
                <Form.Label className="Label">Bank Name</Form.Label>
                <Form.Select
                  required
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                >
                  <option value="">Choose Bank</option>
                  {banksArray.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="valid">
                  Bank Selection is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Bank Name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-2" controlId="accountType">
                <Form.Label className="Label">Account Type</Form.Label>
                <Form.Select
                  required
                  value={accountType}
                  onChange={(e) => setAccountType(e.target.value)}
                >
                  <option value="">Choose Account Type</option>
                  <option value="Cheque / Current">Cheque / Current</option>
                  <option value="Savings">Savings</option>
                  <option value="Other">Other</option>
                </Form.Select>
                <Form.Control.Feedback type="valid">
                  Account Type is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please Enter a Account Type.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-2" controlId="accountNumber">
                <Form.Label className="Label">Account Number</Form.Label>
                <Form.Control
                  required
                  className="Control"
                  value={accountNumber}
                  type="text"
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
                <Form.Control.Feedback type="valid">
                  Account Number is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please Enter a Account Number.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-2" controlId="debitOrderDate">
                <Form.Label className="Label">Debit Order Date</Form.Label>
                <Form.Select
                  required
                  value={debitOrderDate}
                  onChange={(e) => setDebitOrderDate(e.target.value)}
                >
                  <option value="">Choose Debit Date</option>
                  {dateArray.length > 0 && dateArray.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="valid">
                  Debit Date Selection is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Debit Date.
                </Form.Control.Feedback>
              </Form.Group>
            </div>}

            <div className="content-text mt-4" id="paymentTerms">
              {!debitOrder ? (
                <div className="text-center text-sm">
                  <p>
                    I authorise the Accountant of the Employer Group {employeeDetails?.employerName} to deduct the monthly payments from my salary as per the above authority provided and remit it to Shield Life of which I am a member / Insured,  each and every month and continuing until cancelled by me in writing or until I substitute it with a new authorisation.  I have read and accepted the terms and conditions as set out below.
                    <br />
                    <br />
                    <em>Terms & conditions</em>
                    <br />
                    <br />
                    I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.
                    <br />I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts which have been withdrawn while this authority was in force, if such amounts were legally owing to you.
                    <br />
                    Should the premium rate be adjusted by Shield Life as a result of a general decrease/increase in subscription or should I request Shield Life to decrease/increase the premium for certain reason, I confirm that the adjusted premium may be deducted.
                    <br />
                    I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.
                  </p>
                </div>) : (<div className="text-center text-sm mt-4">
                  <p>
                    I authorise Shield Life to issue and deliver debit order payment instructions to your banker for collection against my Account and Bank used by my employer to deposit my salaries / wages, which account may differ from the account specified above on condition that the sum of each payment instruction and frequency of payment requests will never exceed the obligations as agreed and defined in the Agreement specified.
                    <br />
                    My bank’s participation in the relevant payment stream, the authentication mechanism used by me and the relevant rules applicable to the payment streams will determine per repayment cycle the most suitable payment stream in order to fulfil the obligations as defined in the agreement.  My authorisation is subject thereto that the same payment instruction may never be presented simultaneously in more than one payment stream during the same repayment cycle or, if unpaid in a payment stream selected be represented in the same cycle in another payment stream.  I can only service the obligations defined in here if the payment instructions are executed as close as possible to when I receive my salary or wages which dates vary from month to month, especially during December of each year. To curb against (1) unpaid bank charges (2) losing the benefits described in the agreement quoted (3) incurring penalties due to non payment; I explicitly authorise Shield Life to utilise the functionality of Tracking supported on the Debicheck Payment Streams especially after unsuccessful attempts on the Debicheck Payment Stream.
                    <br />
                    Tracking supported on the Debicheck Payment Streams has been explained to me and I acknowledge that my above mentioned account will be interrogated for a defined period until this period has lapsed or until payment has been received.  I hereby agree that subsequent payment instructions will continue to be delivered in terms of the authority until all obligations have been paid.  This authorisation will remain in force until cancelled by me in writing.  I hereby acknowledge that my bank will charge fees to my account as agreed with them once they process this instruction.  I foresee that I may change my bank and bank account particulars reflected in here, in which instance I will notify the beneficiaries specified, or any new beneficiary, should the agreement be ceded or assigned to any third party.  Should I however forget to notify the beneficiary or the assigned third party and if the beneficiary or the assigned third party obtain my new bank particulars, on own account, this mandate will not lapse.  This issued mandate will cover the obtained bank information and the beneficiary and the assigned third party may attach such new information, to this signed document as annexure, and the attached annexure must be read together with this mandate, by my new bank. Date changes agreed to align my salary and wage expected day of payment in accordance with the Agreement concluded with Legal Entity.
                    <br />
                    I understand that the withdrawals hereby authorised will be processed through a computerised system provided by the South African Banks.  I also understand that details of each withdrawal will be printed on my/our bank statement. Such must contain the agreement reference number as stipulated above which number shall enable me to match the withdrawals with this agreement.  I acknowledge that this Authority may be ceded or assigned to a third party but only in the event that this agreement is also ceded or assigned to that third party.
                    <br />
                    <br />
                    <em>Terms & conditions</em>
                    <br />
                    <br />
                    I instruct and authorise Shield Life to draw against my nominated bank account (or any other bank or branch to which I may transfer my account) the amount necessary for the payment of the monthly premium due in respect of my insurance, on the day nominated by me each and every month and continuing until cancelled by me in writing or until I substitute it with a new authorisation.
                    <br />
                    I accept that the authority may be cancelled by me by giving 31 (thirty one) days notice in writing.
                    <br />
                    I understand that should I cancel the authority after the 31-day period, I shall not be entitled to any refund of amounts which have been withdrawn while this authority was in force, if such amounts were legally owing to you.
                    <br />
                    I agree that in the event that the payment date falls on a Saturday, Sunday or recognised South African public holiday, the payment day will automatically be the first preceding business day.
                    <br />
                    I agree that should there be insufficient funds in my account to meet the obligation, Shield Life is entitled  to track my account and re-present the instruction for payment as soon as sufficient funds are available in my account. Such will contain Shield Life as reference which shall enable me to match the withdrawals with this agreement.
                    <br />
                    I agree to pay any bank charges relating to this debit order instruction.
                    <br />
                    Should the premium rate be adjusted by Shield Life as a result of a general decrease/increase in subscription or should I request Shield Life to decrease/increase the premium for certain reason, I confirm that the adjusted premium may be deducted from my bank account.
                    <br />
                    I acknowledge that this Authority may be ceded or assigned to a third party for collection purposes.
                  </p>
                </div>
              )}
            </div>

            <Footer>
              <ul className="btn-list">
                <li className="back-li">
                  <BtnCompo
                    buttonAction={"/beneficiary"}
                    buttonIcon={"/images/icon-back.png"}
                    buttonClass={"btn-back"}
                    buttonIconHeight={22}
                    buttonIconWidth={27}
                  />
                </li>
                <li>
                  <BtnCompo
                    buttonAction={"/rather-call-me"}
                    buttonText={"RATHER CALL ME"}
                    buttonOutline={true}
                    buttonClass={"btn-rather"}
                  />
                </li>
                <li>
                  <Button variant="primary" onClick={(e) => { handleSubmit(e); setApproved(true) }}
                    disabled={(paymentMethod === 'payroll' || alterMethod === 'no') ? !paymentMethod || !alterMethod : !bankName || !accountType || !accountNumber || !debitOrderDate || !alterMethod}>
                    NEXT
                  </Button>
                </li>
              </ul>
            </Footer>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default PaymentMethod;
