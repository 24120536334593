import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import React, { useState, useEffect } from "react";
import { Container, Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import ImgCompo from "components/img-compo";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { browserName, browserVersion } from "react-device-detect";

const Profile = () => {
  const [cellNumberError, setCellNumberError] = useState("");
  const [alternativeNoError, setAlternativeNoError] = useState("");
  const [rankTitleList, setRankTitleList] = useState([]);
  const [rankTitle, setRankTitle] = useState("");
  const [fullNames, setFullNames] = useState("");
  const [surname, setSurname] = useState("");
  const [mainDOB, setMainDOB] = useState("");
  const [mainAge, setMainAge] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [alternativeNo, setAlternativeNo] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [IDNumber, setIDNumber] = useState("");
  const [IDNumberError, setIDNumberError] = useState("");
  const [contactMethod, setContactMethod] = useState({ email: false, sms: false, cellphone: false });
  const [isLoading, setIsLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const [IPAddress, setIPAddress] = useState();
  const [OSDetail, setOSDetails] = useState();

  const navigate = useNavigate();

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIPAddress(res.data.ip)
  };

  useEffect(() => {
    const platform = window.navigator.platform;
    setOSDetails(platform);

    getData();

    axios
      .get("./rankTitle.json")
      .then((res) => {
        if (res.data) setRankTitleList(res.data);
      })
      .catch((err) => console.log(err));

    if (localStorage.getItem("profile")) {
      const profile = JSON.parse(localStorage.getItem("profile"));
      setRankTitle(profile.rankTitle);
      setFullNames(profile.fullNames);
      setSurname(profile.surname);
      setMainDOB(profile.mainDOB);
      setMainAge(profile.mainAge);
      setCellNumber(profile.cellNumber);
      setEmailAddress(profile.emailAddress);
      setIDNumber(profile.IDNumber);
      if (profile.contactMethod) setContactMethod(profile.contactMethod);
      if (profile.alternativeNo) setAlternativeNo(profile.alternativeNo);
    }
  }, []);

  const checkIDNumber = (number) => {
    if (number.length < 13) {
      setIDNumberError("Please give a valid ID Number");
      return false;
    } else {
      setIDNumberError("");
      return true;
    }
  }

  const checkCellNumber = (number, type) => {
    if (number.charAt(0) === "0") {
      if (number.length > 10) {
        if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
        if (type === 'alternativeNo') setAlternativeNoError("Please give a valid cell number");
        return false;
      } else if (number.length < 10) {
        if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
        if (type === 'alternativeNo') setAlternativeNoError("Please give a valid cell number");
        return false;
      } else {
        if (type === 'cellNumber') setCellNumberError("");
        if (type === 'alternativeNo') setAlternativeNoError("");
        return true;
      }
    } else if (number.charAt(0) === "2") {
      if (number.length > 11) {
        if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
        if (type === 'alternativeNo') setAlternativeNoError("Please give a valid cell number");
        return false;
      } else if (number.length < 11) {
        if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
        if (type === 'alternativeNo') setAlternativeNoError("Please give a valid cell number");
        return false;
      } else {
        if (type === 'cellNumber') setCellNumberError("");
        if (type === 'alternativeNo') setAlternativeNoError("");
        return true;
      }
    } else {
      if (type === 'cellNumber') setCellNumberError("Please give a valid cell number");
      if (type === 'alternativeNo' && number.length !== 0) setAlternativeNoError("Please give a valid cell number");
    }
  };

  const changeDOB = (value) => {
    const actualValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
    if (actualValue.length === 8) {
      setMainDOB(value);
      getAge(value);
    }
  };

  const getAge = (dateString) => {
    var now = new Date();
    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();

    var dob = new Date(dateString);
    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearAge = "";

    yearAge = yearNow - yearDob;
    var monthAge;
    if (monthNow >= monthDob) monthAge = monthNow - monthDob;
    else {
      yearAge--;
      monthAge = 12 + monthNow - monthDob;
    }

    var dateAge;
    if (dateNow >= dateDob) dateAge = dateNow - dateDob;
    else {
      monthAge--;
      dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    if (dateAge === 0) dateAge = 1;
    if (monthAge === 0) monthAge = 1;

    age = { years: yearAge, months: monthAge, days: dateAge };
    if (age.years > 0 && age.months > 0 && age.days > 0) ageString = age.years;
    else if (age.years === 0 && age.months > 0 && age.days > 0)
      ageString = "0." + age.months;

    setMainAge(ageString);
  };

  const handleChange = (e, value) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      if (value === 'cellNumber') setCellNumber(e.target.value);
      if (value === 'alternativeNo') setAlternativeNo(e.target.value);
    }
  };

  const generateDOB = (value) => {
    let dob = '';
    const strlenth = (value.length - 1);
    for (var i = 0; i < strlenth; i++) {
      if (i < (strlenth - 6)) {
        dob += value[i];
      }
    }
    if (dob) {
      let year;
      let month = String(dob).substring(2, 4);
      let day = String(dob).substring(4, 6);
      const firstTwoChars = String(dob).substring(0, 2);
      if (firstTwoChars >= 45) year = "19" + firstTwoChars;
      else year = "20" + firstTwoChars;
      let finalDOB = year + "-" + month + "-" + day;
      if (day) setMainDOB(finalDOB);
      getAge(finalDOB);
    }
  }

  useEffect(() => {
    if (approved) {
      if (!IDNumberError && !cellNumberError && !alternativeNoError) {
        setIsLoading(true)
        const profile = {
          rankTitle: rankTitle,
          fullNames: fullNames,
          surname: surname,
          mainDOB: mainDOB,
          mainAge: mainAge,
          cellNumber: cellNumber,
          alternativeNo: alternativeNo,
          emailAddress: emailAddress,
          IDNumber: IDNumber,
          contactMethod: contactMethod
        };

        let representativeData;
        if (localStorage.getItem("representativeData"))
          representativeData = JSON.parse(localStorage.getItem("representativeData"));

        let deviceInfo = {
          IPAddress, OSDetail, browserName, browserVersion, width: window.innerWidth, height: window.innerHeight
        }

        let payload = {
          page: 'profile',
          sourceData: "funeralApp",
          representativeData,
          profile,
          deviceInfo
        }

        var config = {
          method: "post",
          url: "https://apiv2.msgl.ink/",
          headers: {
            "content-type": "application/json",
          },
          data: {
            ...payload,
          },
        };
        axios(config)
          .then(function (response) {
            setIsLoading(false);
            localStorage.setItem("profile", JSON.stringify(profile));
            localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
            navigate("/select-cover");
          })
          .catch(function (error) {
            setIsLoading(false);
          });
      }
    }
  }, [IDNumber, IDNumberError, IPAddress, OSDetail, alternativeNo, alternativeNoError, approved, cellNumber, cellNumberError, contactMethod, emailAddress, fullNames, mainAge, mainDOB, navigate, rankTitle, surname]);

  return (
    <Container className="h-100">
      {isLoading && <div className="loaderOverlay">
        <Spinner animation="border" role="status" variant="light" />

        <div>Submitting Data, please wait...</div>
      </div>}
      <div className="main-content">
        <h1 className="page-title mb-5">TELL US A LITTLE ABOUT YOURSELF!</h1>
        <Form noValidate>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} xs="12">
              <Form.Label htmlFor="rankTitle">Title</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-user.png"
                    alt="Title"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Select
                  required
                  aria-describedby="inputGroupPrepend"
                  aria-label="RankTitle"
                  value={rankTitle}
                  onChange={(e) => setRankTitle(e.target.value)}
                >
                  <option value="">Select Title</option>
                  {rankTitleList.map(({ value, title }) => (
                    <option key={value} value={value}>
                      {title}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="valid">
                  Title is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Title.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12">
              <Form.Label htmlFor="fullNames">First Name *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-user.png"
                    alt="FirstName"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="First Name"
                  aria-describedby="inputGroupPrepend"
                  value={fullNames}
                  onChange={(e) => setFullNames(e.target.value)}
                />
                <Form.Control.Feedback type="valid">
                  FirstName is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please Enter a FirstName.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12">
              <Form.Label htmlFor="surname">Surname *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-user.png"
                    alt="Surname"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="Surname"
                  aria-describedby="inputGroupPrepend"
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                />
                <Form.Control.Feedback type="valid">
                  Surname is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Surname.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12">
              <Form.Label htmlFor="IDNumber">ID Number *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-user.png"
                    alt="Surname"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="ID Number"
                  aria-describedby="inputGroupPrepend"
                  isInvalid={IDNumberError}
                  maxLength={13}
                  value={IDNumber}
                  onChange={(e) => { setIDNumber(e.target.value); generateDOB(e.target.value); checkIDNumber(e.target.value) }}
                />
                <Form.Control.Feedback type="invalid">
                  {IDNumberError
                    ? IDNumberError
                    : "Please Enter a ID Number."}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="6">
              <Form.Label htmlFor="mainDOB">Date Of Birth *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-birthdate.png"
                    alt="DateOfBirth"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="date"
                  placeholder="DateOfBirth"
                  aria-describedby="inputGroupPrepend"
                  onChange={(e) => changeDOB(e.target.value)}
                  value={mainDOB}
                />
                <Form.Control.Feedback type="valid">
                  Date Of Birth is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please choose a Date Of Birth.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="6">
              <Form.Label htmlFor="mainAge">Age</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-age.png"
                    alt="Age"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Age"
                  aria-describedby="inputGroupPrepend"
                  value={mainAge}
                  disabled
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12">
              <Form.Label htmlFor="cellNumber">Cell Phone Number *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-phone.png"
                    alt="CellNumber"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  placeholder="Cell Phone Number"
                  aria-describedby="inputGroupPrepend"
                  value={cellNumber}
                  isInvalid={cellNumberError}
                  maxLength={11}
                  pattern="[0-9]*"
                  onChange={(e) => { handleChange(e, 'cellNumber'); checkCellNumber(e.target.value, 'cellNumber') }}
                />
                <Form.Control.Feedback type="invalid">
                  {cellNumberError
                    ? cellNumberError
                    : "Please Enter a Cell Phone Number."}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12">
              <Form.Label htmlFor="alternativeNo">Alternative Number</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-phone.png"
                    alt="alternativeNo"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Alternative Number"
                  aria-describedby="inputGroupPrepend"
                  value={alternativeNo}
                  isInvalid={alternativeNoError}
                  maxLength={11}
                  pattern="[0-9]*"
                  onChange={(e) => { handleChange(e, 'alternativeNo'); checkCellNumber(e.target.value, 'alternativeNo') }}
                />
                <Form.Control.Feedback type="invalid">
                  {alternativeNoError && alternativeNoError}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} xs="12">
              <Form.Label htmlFor="emailAddress">Email Address *</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">
                  <ImgCompo
                    src="/images/icon-email.png"
                    alt="Email"
                    width={16}
                    height={16}
                  />
                </InputGroup.Text>
                <Form.Control
                  required
                  type="email"
                  placeholder="Email Address"
                  aria-describedby="inputGroupPrepend"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
                <Form.Control.Feedback type="valid">
                  Email Address is valid.
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Please Enter a Email Address.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="IDNumber">Preferred Contact Method</Form.Label>
              <div className="d-flex gap-3 w-100">
                <Form.Check
                  checked={contactMethod.email}
                  type="checkbox"
                  id="check1"
                  label="Email"
                  value={"Email"}
                  onChange={(e) => { if (e.target.checked) setContactMethod({ ...contactMethod, email: true }); else setContactMethod({ ...contactMethod, email: false }) }}
                />
                <Form.Check
                  checked={contactMethod.sms}
                  type="checkbox"
                  id="check2"
                  label="SMS"
                  value={"SMS"}
                  onChange={(e) => { if (e.target.checked) setContactMethod({ ...contactMethod, sms: true }); else setContactMethod({ ...contactMethod, sms: false }) }}
                />
                <Form.Check
                  checked={contactMethod.cellphone}
                  type="checkbox"
                  id="check3"
                  label="Cellphone"
                  value={"Cellphone"}
                  onChange={(e) => { if (e.target.checked) setContactMethod({ ...contactMethod, cellphone: true }); else setContactMethod({ ...contactMethod, cellphone: false }) }}
                />
              </div>
            </Form.Group>
          </Row>

          <Footer>
            <ul className="btn-list">
              <li className="back-li">
                <BtnCompo
                  buttonAction={"/terms-condition"}
                  buttonIcon={"/images/icon-back.png"}
                  buttonClass={"btn-back"}
                  buttonIconHeight={22}
                  buttonIconWidth={27}
                />
              </li>
              <li>
                <BtnCompo
                  buttonAction={"/rather-call-me"}
                  buttonText={"RATHER CALL ME"}
                  buttonOutline={true}
                  buttonClass={"btn-rather"}
                />
              </li>
              <li>
                <Button variant="primary" onClick={(e) => { setApproved(true) }} disabled={!rankTitle || !fullNames || !surname || !mainAge || !cellNumber || !emailAddress || !IDNumber || cellNumberError}>
                  NEXT
                </Button>
              </li>
            </ul>
          </Footer>
        </Form>
      </div>
    </Container>
  );
};

export default Profile;
